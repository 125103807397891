import React, { useState, useEffect } from "react";
import logoImage from "../../assets/img/safecamp_BRAND.1-nobg.png";
import { useHistory, Redirect } from "react-router-dom";
import { phoneFormatter, setPhoneNo, medFlowInLocalStorage, personalizationLocalStorage } from "utils";
import { Auth } from "aws-amplify";
import API from "api";
import { userCompanyID, loggedInUser } from "utils";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import { NEW_PERSONALIZE } from "constant";
import SweetAlert from "react-bootstrap-sweetalert";
import LoginProfileSelection from "./LoginProfileSelection";
import { personalizationLocalStorageHR } from "utils";
import { PERSONALIZATION } from "constant";
import ShowAlertMessage from "components/ShowAlertMessage";

const Login = () => {
  const [phone, setPhone] = useState("+1");
  const [password, setPassword] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [companyList, setCompnayList] = useState([]);
  const [profileSelection, setPrfileSelection] = useState(false);
  const [multiProfileData, setMultiProfileData] = useState([]);
  const [loginUser, setLoginUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  let history = useHistory();
  let url = window.location.href;

  useEffect(() => {
    const getUSer = async () => {
      const user = await API.getCurrentUser();
      const isLoggedIn = medFlowInLocalStorage.get();
      if (user && isLoggedIn) {
        redirectToAdmin();
      } else {
        const companyData = await API.getCompnayList();
        setCompnayList(companyData);
      }
    };
    getUSer();
  }, []);

  const handleSubmit = async () => {
    if (!phone) {
      setPhoneError("please enter the phone");
      return;
    }
    if (!password) {
      setPasswordError("please enter the password");
      return;
    }

    //  const attributePayload = {
    //   email: "pybertest@gmail.com",
    //   preferred_username: "superadmin",
    //   phone_number: "+17777777777",
    //   "custom:role": "Admins",
    //   "custom:autoConfirm": "true",
    //   "custom:firstName": "Super",
    //   "custom:lastName": "Admin",
    //   "custom:note": "System",
    //   "custom:labID":"312c3c36-f69a-4f29-acba-cfa2d6c4046c"
    // };

    // const signUp = await Auth.signUp({
    //   username: "+17777777777",
    //   password: "Test1234!",
    //   attributes: attributePayload,
    // });
    try {
      setDisableBtn(true);
      const user = await Auth.signIn(phone, password);
      const compnayID = user.attributes["custom:labID"];
      let userCompanies = await API.getEmployeeClients(user.username);
      console.log("userCompanies", userCompanies);
      let employee = null;
      if (!userCompanies.clients) {
        employee = await API.getEmplyeeCompany(user.username);
        console.log("employee", employee);
        userCompanies.clients = employee?.users?.map((m) => m.companyID);
      }
      let activeCompany = null;
      // if user is assigned any company set default 0 index
      // if (userCompanies) {
      //   const arr = userCompanies.split(",");
      //   if (arr.length > 0) activeCompany = arr[0];
      // }
      if (url.includes("localhost")) {
        url = "test.safecamphr.com";
      }
      // if (phone === "+19999999999") {
      //   url = "severance.safecamphr.com";
      // }
      // if (phone === "+13473516633" || phone === "+19999999999") {
      //   url = "www.lb2.safecamphr.com";
      // }
      // if (phone === "+17777777777") {
      //   url = "zone.safecamphr.com";
      // }
      // if (phone === "+18189031049") {
      //   url = "severance.safecamphr.com";
      // }

      if (!url.includes("localhost")) {
        let companiesData = [...companyList];
        if (companyList.length === 0) {
          companiesData = await API.getCompnayList();
        }
        const urlData = companiesData.filter((c) => url.includes(c.companyWeb));

        if (employee && employee.users.length > 1) {
          const isMulti = employee.users.filter((f) => f.companyID === urlData[0].id);
          if (isMulti.length > 1) {
            setMultiProfileData(isMulti);
            setPrfileSelection(true);
            setLoginUser(user);
            return;
          }
        }

        if (urlData.length > 0 && userCompanies.clients && userCompanies.clients.includes(urlData[0].id)) {
          activeCompany = urlData[0].id;
        } else {
          activeCompany = null;
        }
      }

      if (!activeCompany) {
        await Auth.signOut();
        setDisableBtn(false);
        setErrorMessage("Not Authorized");
        medFlowInLocalStorage.clear();
        userCompanyID.clear();
        return;
      }
      loggedInUser.save({
        name: employee?.users[0]
          ? `${employee.users[0].firstName} ${employee.users[0].lastName}`
          : `${user.attributes["custom:firstName"]} ${user.attributes["custom:lastName"]}`,
        schrID: employee?.users[0].schrID,
      });
      userCompanyID.save(activeCompany);
      medFlowInLocalStorage.save();
      personalizationLocalStorage.save(
        userCompanies.personalization || user.attributes["custom:personalisation"] || JSON.stringify(PERSONALIZATION)
      );

      redirectToAdmin();
    } catch (error) {
      setDisableBtn(false);
      setErrorMessage(error.message);
    }
  };

  const handleKeyDownEvent = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleProfileSelection = (emp) => {
    loggedInUser.save({ name: `${emp.firstName} ${emp.lastName}`, schrID: emp.schrID });
    userCompanyID.save(emp.companyID);
    medFlowInLocalStorage.save();
    personalizationLocalStorage.save(
      loginUser.attributes["custom:personalisation"] ||
        JSON.stringify({
          personalize:
            "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25:1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25",
          employeeAll: "0",
          programAll: "0",
        })
    );
    redirectToAdmin();
  };

  const redirectToAdmin = () => {
    if (history) history.push("/admin/employees");
    else window.location.reload();
  };

  const isLoggedIn = medFlowInLocalStorage.get();
  if (isLoggedIn) redirectToAdmin();
  return (
    <>
      <div className="loginWrapper">
        <div className="login-left-side">
          <div className="text-center">{<img src={logoImage} alt="logo" className="logo-width" />}</div>
        </div>
        <div className="login-right-side">
          <h3 className="mt-0">Login</h3>
          <p className="tag-line">Sign in to your account</p>
          <div className="form-group">
            <PhoneNoInput handleChange={(e) => setPhone(e)} value={phone} />
          </div>
          <div className="input-group group-wrapper mt-3">
            <span className="input-group-text">
              <i className="fa fa-lock" />{" "}
            </span>
            <input
              className="form-control"
              placeholder="Enter your password"
              minLength={8}
              value={password}
              type="password"
              onKeyDown={handleKeyDownEvent}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="text-right w-100">
            <button
              type="submit"
              disabled={disableBtn}
              className="text-uppercase w-100 btn signin-btn"
              onClick={handleSubmit}
            >
              Sign In
            </button>
          </div>
        </div>
      </div>
      {errorMessage && <ShowAlertMessage message={errorMessage} handleClose={() => setErrorMessage("")} error />}
      {profileSelection && (
        <LoginProfileSelection employees={multiProfileData} handleSelection={handleProfileSelection} />
      )}
    </>
  );
};

export default Login;
