import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import {
  Employee,
  Question,
  Schedule,
  AppSetting,
  BlockOutDays,
  TestTypes,
  Department,
  Locations,
  Regions,
  Programs,
  EmployeeQuestion,
  EmployeeDailyLog,
  GeneralCallTime,
} from "./models";
import { Auth, API, Storage, graphqlOperation } from "aws-amplify";
import * as queries from "./graphql/queries";
import { toTitleCase, capitalizeLetter, formatCallTime, getTodayDate } from "utils";
import {
  TEST_STATUS,
  SCHEDULE_CATEGORY,
  DAILY_QA_STATUS,
  TEST_TYPE_VALUE,
  TIME_ZONE_OFFSET,
  SCHEDULE_TYPES_VALUE,
  DEVTEST_COMPANY,
} from "constant";
import axios from "axios";
import AWS from "aws-sdk";
import awsconfig from "./aws-exports";
import { jsonToCSV } from "react-papaparse";
import moment from "moment";
import { userCompanyID, loggedInUser } from "utils";
import FileSaver from "file-saver";
import { v4 as uuidv4 } from "uuid";
import { AWS_ENDPOINT } from "constants/endpoints";
import { STATUS, Zone_Audit_Types } from "constant";
import { uniqueEmpIds, findAndReplaceDailyTaskArray } from "utils";
import { getZoneLevelSetting } from "utils";
import { UPDATE_CALL_TIME_CHOICE } from "constant";
import { getDaysArray } from "utils";
import { Sentry } from "react-activity";
import { PERSONALIZATION } from "constant";
import { currentDate, getZoneLevelCustomTestsName } from "utils";
import { CUSTOM_CALL_TIME } from "constant";

const MAX_PAGE_LIMIT = 1000;

const getTestGraphQL = async () => {
  console.log("getTestGraphQL ");
  try {
    const graphqlData = await API.graphql(
      graphqlOperation(queries.getEmployee, { id: "57b4f60a-acec-4150-8224-49eb3d2e5c8e" })
    );
    console.log("getTestGraphQL ", graphqlData);
  } catch (err) {
    console.log("Error ", err);
  }
};

const getCurrentUser = async () => {
  try {
    const response = await Auth.currentAuthenticatedUser({ bypassCache: true });

    const compnayID = userCompanyID.get();
    // userCompanyID.save(compnayID);
    return {
      ...response.attributes,
      name: `${response.attributes["custom:firstName"]} ${response.attributes["custom:lastName"]}`,
      roles: response.signInUserSession.accessToken.payload["cognito:groups"] || [],
      isAdmin: function () {
        return this.roles.some((role) => role === "Admins");
      },
      isLab: function () {
        return this.roles.some((role) => role === "Labs");
      },
      isUser: function () {
        return this.roles.length === 0;
      },
      isSite: function () {
        return this.roles.some((role) => role === "Employers");
      },
      isEmployee: function () {
        return this.roles.some((role) => role === "Employees");
      },
      companyID: compnayID,
    };
  } catch (err) {
    console.log(err);
    localStorage.clear();
    return null;
  }
};

const getAllUsers = async () => {
  const models = await DataStore.query(Employee);
  return models;
};

const getTestDetailFromMD = async (seqNo) => {
  console.log("[getTestDetailFromMD]");
  const companyID = userCompanyID.get();
  const flag = DEVTEST_COMPANY.includes(companyID) ? 0 : 1;
  const path = "/getTestDetailFromMD";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      sequenceNo: parseInt(seqNo),
      flag,
    },
  };

  const apiRes = await API.post(apiName, path, params);
  return apiRes;
};

const signIn = async (username, password) => {
  try {
    const response = await Auth.signIn(username, password);
    // console.log(response);
    return {
      ...response.attributes,
      roles: response.signInUserSession.accessToken.payload["cognito:groups"] || [],
      isAdmin: function () {
        return this.roles.some((role) => role === "Admins");
      },
      isUser: function () {
        return this.roles.length === 0;
      },
    };
  } catch (err) {
    return null;
  }
};

const createEmployee = async (demographics = {}, clientID, phoneNumber) => {
  const models = await DataStore.query(Employee, (emp) =>
    emp
      .clientID("eq", clientID)
      .first("eq", demographics.firstName)
      .last("eq", demographics.lastName)
      .dob("eq", buildAWSDate(demographics.dob))
  );
  if (models.length > 0) {
    return models[0];
  }
  //Must be new. Create it
  const employee = await DataStore.save(
    new Employee({
      first: demographics.firstName,
      last: demographics.lastName,
      dob: buildAWSDate(demographics.dob),
      id_number: demographics.idNumber,
      clientID: clientID,
      phone_number: phoneNumber,
      street: demographics.street,
      street2: demographics.street2,
      city: demographics.city,
      state: demographics.state,
      zip: demographics.zip,
      companyID: userCompanyID.get(),
    })
  );
  return employee;
};

const apiName = "mfaAdminApi";

const fetchUsersInGroup = async (groupName) => {
  let path = "/listUsersInGroup";
  let myInit = {
    queryStringParameters: {
      groupname: groupName,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const models = await API.get(apiName, path, myInit);
    return models.Users;
  } catch (e) {
    console.log("Error getting users:", e);
  }
};
const fetchAllUsers = async (filter, NextToken) => {
  let path = "/listUsers";
  let params = {
    queryStringParameters: {
      limit: 60,
      filter: filter,
      token: NextToken,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const models = await API.get(apiName, path, params);
  return models;
};

const getAllUsersForShow = async (user) => {
  let path = "/users";
  let params = {
    body: {
      companyID: user.phone_number === "+19999999999" ? "" : userCompanyID.get(),
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  try {
    const models = await API.post(apiName, path, params);
    return models?.data || [];
  } catch (err) {
    console.log("error", err);
  }
};

const fetchAllUsersInGroup = async (groupName) => {
  const path = "/listUsersInGroup";
  let NextToken = null;
  let users = [];
  try {
    do {
      const myInit = {
        queryStringParameters: {
          groupname: groupName,
          limit: 60,
          token: NextToken,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
      };
      const models = await API.get(apiName, path, myInit);
      NextToken = models.NextToken || null;
      users = users.concat(models.Users);
    } while (NextToken);
    return users;
  } catch (e) {
    console.log("Error getting users:", e);
  }
};

const changeUserRole = async (prevRole, newRole, username) => {
  let path = "/removeUserFromGroup";
  let prevRoleAlias;
  if (prevRole === "Crew") {
    prevRoleAlias = "Employee";
  } else if (prevRole === "Health & Safety") {
    prevRoleAlias = "Employer";
  } else {
    prevRoleAlias = prevRole;
  }
  let params = {
    body: {
      username: username,
      groupname: prevRoleAlias + "s",
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const roleRemoval = await API.post(apiName, path, params);
    path = "/addUserToGroup";
    params.body["groupname"] = newRole + "s";
    const roleChange = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to change user role");
  }
};

const confirmUserSignUp = async (username) => {
  const path = "/confirmUserSignUp";
  try {
    const params = {
      body: {
        username: username,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const done = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Fail to confirm user");
  }
};
const updateUserInfo = async (
  user,
  clientName,
  role,
  associatedClients,
  clientMainID,
  mainContactName,
  clientCordID,
  testCordName
) => {
  const path = "/updateUserInfo";

  const params = {
    body: {
      username: user.username,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      note: clientName,
      clientID: user.clientID,
      mainContact: user.mainContact,
      clients: user.clients,
      privilege: user.privilege || clientName,
      role,
      phoneNumber: user.username,
      associatedClients: associatedClients,
      clientMainID,
      mainContactName,
      clientCordID,
      testCordName,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to update user info");
  }
};

const addUserClients = async (
  username,
  user,
  role,
  phoneNumber,
  associatedClients,
  clientMainID,
  mainContactName,
  clientCordID,
  testCordName,
  clientName
) => {
  const path = "/addUserClients";

  const params = {
    body: {
      username: username,
      clients: user.clients,
      role: role,
      phoneNumber: phoneNumber,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      note: clientName,
      privilege: user.privilege || clientName,
      personalization: JSON.stringify(PERSONALIZATION),
      associatedClients: associatedClients,
      clientMainID,
      mainContactName,
      clientCordID,
      testCordName,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing addUserClients:", e);
  }
};

const udpatePersonalization = async (user, data) => {
  const path = "/updatePersonalisationInfoNew";
  let note = "";

  const params = {
    body: {
      data: JSON.stringify(data),
      username: user, // user.phone_number,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
  }
};

const updateAssociatedInfo = async (username, note) => {
  const path = "/updateAssociatedInfo";

  const params = {
    body: {
      username: username,
      note: note,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
  }
};

const updateUserPassword = async (username, password) => {
  const path = "/updateUserPassword";
  const params = {
    body: {
      username: username,
      password: password,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to update user password");
  }
};

const deleteUserLinkFromEmployee = async (val) => {
  const users = await DataStore.query(Employee, (e) => e.subID("eq", val));
  users.forEach(async (user) => {
    await DataStore.save(
      Employee.copyOf(user, (updated) => {
        updated.subID = null;
      })
    );
  });
};

const deleteUser = async (username) => {
  const path = "/deleteUser";
  const params = {
    body: {
      username: username,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to update user password");
  }
};

const sendSMSNotification = async (phoneNumber, message) => {
  try {
    const notification = await axios.post(AWS_ENDPOINT + "/notification", {
      phone_number: phoneNumber,
      message: message,
    });
    return notification;
  } catch (error) {
    console.log(error);
  }
};

const parseMessage = (msg, emp, companyData, testType, zoneName, setting, deptName) => {
  let message = msg;
  message = message.replace(/{firstName}/g, toTitleCase(emp.firstName));
  message = message.replace(/{lastName}/g, toTitleCase(emp.lastName));
  message = message.replace(/{testType}/g, testType);
  message = message.replace(/{callTime}/g, formatCallTime(emp.callTime));
  message = message.replace(/{zone}/g, zoneName || emp.programName || "");
  message = message.replace(/{showName}/g, setting.companyName || "");
  message = message.replace(/{deptName}/g, deptName || emp.departmentName || "");
  message = message.replace(/{todaysDate}/g, moment().format("MM-DD") || "");
  message = message.replace(/{tomorrowsDate}/g, moment().add(1, "day").format("MM-DD") || "");
  message = message.replace(/{mainContactName}/g, setting.contactName || "");
  message = message.replace(/{mainContactNumber}/g, setting.contactNumber || "");
  message = message.replace(/{mainContactEmail}/g, setting.contactEmail || "");
  message = message.replace(/{testingCordinatorName}/g, setting.testingCordName || "");
  message = message.replace(/{testingCordinatorEmail}/g, setting.testingCordEmail || "");
  message = message.replace(/{testingCordinatorNumber}/g, setting.testingCordNumber || "");
  return message;
};

const parseMessageCallTime = (msg, emp, testType, callTime, setting, deptName) => {
  let formattedCallTime = formatCallTime(callTime);
  let message = msg;
  message = message.replace(/{firstName}/g, toTitleCase(emp.firstName));
  message = message.replace(/{lastName}/g, toTitleCase(emp.lastName));
  message = message.replace(/{testType}/g, testType);
  message = message.replace(/{callTime}/g, formattedCallTime);
  message = message.replace(/{zone}/g, emp.programName || "");
  message = message.replace(/{showName}/g, setting.companyName || "");
  message = message.replace(/{deptName}/g, deptName || emp.departmentName || "");
  message = message.replace(/{todaysDate}/g, moment().format("MM-DD") || "");
  message = message.replace(/{tomorrowsDate}/g, moment().add(1, "day").format("MM-DD") || "");
  message = message.replace(/{mainContactName}/g, setting.contactName || "");
  message = message.replace(/{mainContactNumber}/g, setting.contactNumber || "");
  message = message.replace(/{mainContactEmail}/g, setting.contactEmail || "");
  message = message.replace(/{testingCordinatorName}/g, setting.testingCordName || "");
  message = message.replace(/{testingCordinatorEmail}/g, setting.testingCordEmail || "");
  message = message.replace(/{testingCordinatorNumber}/g, setting.testingCordNumber || "");
  return message;
};

export const formatDateMDY = (date) => (date ? moment(date).format("MM/DD/YYYY") : "");
const buildAWSDate = (dobFromID) => {
  const awsdob = [];
  awsdob.push(dobFromID.substring(4));
  awsdob.push(dobFromID.substring(0, 2));
  awsdob.push(dobFromID.substring(2, 4));
  return awsdob.join("-");
};

const sendEmail = async (data) => {
  const path = "/sendEmail";
  // const path = "/sendEmailAttachement";

  const params = {
    body: {
      data: data,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error Email Send:", e);
  }
};

let type = "";
if (window.location.host.includes("localhost") || window.location.host.includes("test.safecamphr.com")) {
  type = "_test";
}
const sendEmailToPendingRelease = async (tests) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_email${type}.csv`, jsonToCSV(tests), {
    bucket: "result-update",
  });
};

const sendEmailToCrew = async (data) => {
  // const path = "/sendEmailToCrew";
  const path = "/sendEmailAttachement";

  const params = {
    body: {
      data: data,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error Email Send:", e);
  }
};

const fetchAllEmployees = async () => {
  const models = await DataStore.query(Employee);
  return models.length;
};

const fetchAllCustomCallTime = async () => {
  const models = await DataStore.query(GeneralCallTime, Predicates.ALL);
  return models;
};

const updateCustomCallTime = async (dates, time) => {
  const compnayID = userCompanyID.get();
  for (let loop = 0; loop < dates.length; loop++) {
    const models = await DataStore.query(GeneralCallTime, (f) =>
      f.gctDate("eq", dates[loop]).companyID("eq", compnayID)
    );
    if (models.length > 0) {
      await DataStore.save(
        GeneralCallTime.copyOf(models[0], (updated) => {
          updated.gctDate = dates[loop];
          updated.gctTime = time;
        })
      );
    } else {
      await DataStore.save(
        new GeneralCallTime({
          companyID: compnayID,
          gctDate: dates[loop],
          gctTime: time,
        })
      );
    }
  }
};

const deleteCustomCallTimeDates = async (dates) => {
  const compnayID = userCompanyID.get();
  for (let loop = 0; loop < dates.length; loop++) {
    const models = await DataStore.query(GeneralCallTime, (f) =>
      f.gctDate("eq", dates[loop]).companyID("eq", compnayID)
    );
    if (models.length > 0) {
      await DataStore.delete(GeneralCallTime, (obj) => obj.id("eq", models[0].id));
    }
  }
};

const getEmployees = async () => {
  const models = await DataStore.query(Employee, (e) => e.companyID("eq", userCompanyID.get()), {
    sort: (emp) => emp.createdAt(SortDirection.DESCENDING),
  });
  return models;
};

const getEmployeeById = async (empId) => {
  const models = await DataStore.query(Employee, (c) => c.or((c) => empId.reduce((c, id) => c.id("eq", id), c)), {
    sort: (emp) => emp.createdAt(SortDirection.DESCENDING),
  });
  return models.length > 0 ? models : null;
};

const getlatestEmployee = async (id) => {
  const model = await DataStore.query(Employee, (e) => e.id("eq", id));
  const dbEmp = model[0];
  return dbEmp;
};
const removeExternalTest = async (empID, test, checkInRemove) => {
  const model = await DataStore.query(Employee, (e) => e.id("eq", empID));
  const dbEmp = model[0];

  const dbTestHistory = dbEmp.testHistory.filter((f) => f.externalTestFile !== test.filePath);
  await DataStore.save(
    Employee.copyOf(dbEmp, (updated) => {
      updated.testHistory = dbTestHistory;
    })
  );
  const updateTests = [
    {
      id: dbEmp.id,
      programID: dbEmp.programID,
      companyID: dbEmp.companyID,
      firstName: dbEmp.firstName,
      lastName: dbEmp.lastName,
      checkIn: checkInRemove,
      department: dbEmp.department,
      type: test.test_type,
    },
  ];
  await updateEmpTestSchedule(updateTests);
};

const addLogsRemoveExternalTest = async (test, emp, user, checkInID) => {
  const path = "/deleteExternalTest";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: test.id,
      userName: user.name,
      companyID: emp.companyID,
      testDate: test.createdAt,
      checkInID: checkInID,
      schrID: emp.id,
      filePath: test.filePath,
      test_type: test.test_type || test.value,
    },
  };

  try {
    const res = await API.post(apiName, path, params);
  } catch (error) {
    console.log("error: ", error);
  }
};

const updateExternalTestResult = async (testData) => {
  const path = "/updateTestResult";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      data: testData,
    },
  };

  try {
    const res = await API.post(apiName, path, params);
    return res;
  } catch (error) {
    console.log("error: ", error);
  }
};

const saveEmployeeLogs = async () => {
  const models = await DataStore.query(Employee, (e) => e.companyID("eq", userCompanyID.get()));
  let logs = [];
  for (let i = 0; i < models.length; i++) {
    const l = await DataStore.query(EmployeeQuestion, (e) => e.employeeID("eq", models[i].id), {
      sort: (emp) => emp.createdAt(SortDirection.DESCENDING),
    });
    const emp = models[i];
    for (let j = 0; j < l.length; j++) {
      const ans = l[j];
      logs.push({
        firstName: j == 0 ? emp.firstName : "",
        lastName: j == 0 ? emp.lastName : "",
        email: j == 0 ? emp.email : "",
        phoneNumber: j == 0 ? emp.phoneNumber : "",
        qus: ans.question,
        ans: ans.answer,
        date: moment(ans.createdAt).format("MM-DD-YYYY"),
      });
    }
  }
  try {
    const content = jsonToCSV(logs);
    const fileContent = new Blob([content], { type: "csv" });
    await FileSaver.saveAs(fileContent, "EmployeeLog.csv");
  } catch (err) {
    console.log("Error", err);
  }

  return models;
};

const updateCompnayEmployeeNo = async (number) => {
  const setting = await getCompanySetting();
  await DataStore.save(
    AppSetting.copyOf(setting, (updated) => {
      updated.employeeCounter = number;
    })
  );
};
// const updateBlockOut = async (dates) => {
//   const setting = await getCompanySetting();
//   await DataStore.save(
//     AppSetting.copyOf(setting, (updated) => {
//       updated.blockOutDays = JSON.stringify(dates);
//     })
//   );
// };

const updateBlockOut = async (dates, workingDates) => {
  const dbBlock = await getBlockOutDays();
  const workDate = workingDates.filter((value, index, self) => index === self.findIndex((t) => t.date === value.date));
  const blockOutDate = dates.filter(
    (value, index, self) => index === self.findIndex((t) => t.notesDate === value.notesDate)
  );
  // const dates = workingDates.map((m) => m.date);
  // const uniqueDates = [...new Set(dates)];
  // const workingDate = uniqueDates.map((m) => {
  //   return { date: m };
  // });
  if (dbBlock.length > 0) {
    const model = dbBlock[0];
    await DataStore.save(
      BlockOutDays.copyOf(model, (updated) => {
        updated.blockOutDays = blockOutDate;
        updated.workingDays = workDate;
      })
    );
  } else {
    await DataStore.save(
      new BlockOutDays({
        blockOutDays: dates,
        workingDays: workingDates,
        companyID: userCompanyID.get(),
      })
    );
  }
};

const getBlockOutDays = async () => {
  const res = await DataStore.query(BlockOutDays, (d) => d.companyID("eq", userCompanyID.get()));
  return res;
};

const uploadExternalTest = async (obj, portal) => {
  const path = "https://9dpj6yddw2.execute-api.eu-west-1.amazonaws.com/default/externaltest";

  const params = { headers: { "X-API-KEY": "I9KEyDzGIB9sCeovRZjsy9bNERk8duCYIFdMMs80" } };
  const body = {
    employeeID: obj.id,
    companyID: obj.companyID,
    phoneNumber: obj.phoneNumber,
    firstName: obj.firstName,
    lastName: obj.lastName,
    email: obj.email,
    gender: obj.gender || obj.sex,
    dob: obj.dob,
    id_number: obj.idNumber,
    street: obj.street,
    street2: obj.street2,
    zip: obj.zip,
    city: obj.city,
    state: obj.city,
    country: obj.country,
    countryCode: obj.countryCode,
    userName: obj.userName || `${obj.firstName || ""} ${obj.lastName || ""}`.trim(),
    test_type: obj.test_type,
    result: obj.result,
    externalTestFile: obj.result !== "Inconclusive" ? obj.externalTestFile : null,
    testLocation: obj.testLocation,
    test: obj.test,
    publicExternalTest: obj.publicExternalTest,
  };
  const res = await axios.post(path, body, params);

  if (obj.externalTestImage instanceof Object && portal && obj.result !== "Inconclusive") {
    await Storage.put(obj.externalTestFile, obj.externalTestImage, { bucket: "employee-external-tests" });
  }
  return res;
};

const checkTodayEmployeeQuestionAns = async (employee) => {
  let today = getTodayDate();
  const empQuestions = await DataStore.query(EmployeeQuestion, (emp) =>
    emp.employeeID("eq", employee.id).createdAt("gt", today)
  );
  for (let i = 0; i < empQuestions.length; i++) {
    await DataStore.delete(empQuestions[i]);
  }
};

const addEmployeeQuestion = async (emp, questions, isAsnwer) => {
  if (!emp || !questions) return;
  await checkTodayEmployeeQuestionAns(emp);
  const ttl = questions.length;
  for (let i = 0; i < ttl; i++) {
    const item = questions[i];
    await DataStore.save(
      new EmployeeQuestion({
        employeeID: emp.id,
        question: item.name,
        answer: item.answer,
      })
    );
  }
  if (!isAsnwer) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
    const dbEmp = models[0];
    const employee = await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.qusAttempted = (dbEmp.qusAttempted || 0) + 1;
        updated.scheduleData = {
          ...(dbEmp.scheduleData || {}),
          quesDate: moment().format("YYYY-MM-DD"),
          questResult: "0",
        };
      })
    );
    return employee;
  }
  return false;
};

const testFunc = async (emp) => {
  const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
  const dbEmp = models[0];
  const employee = await DataStore.save(
    Employee.copyOf(dbEmp, (updated) => {
      updated.qusAttempted = 0;
    })
  );
  return employee;
};

const updateEmployeeQuestionList = async (emp, userName) => {
  const dailyTask = findAndReplaceDailyTaskArray(emp.dailyTask || []);
  let isScreeningDone = false;
  // if (isTestCompletedCheckOnQuestioningSubmit(emp.testOne) && isTestTwoCompletedCheckOnQuestioningSubmit(emp.testTwo)) {
  //   isScreeningDone = true;
  // }
  const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
  const dbEmp = models[0];
  const employee = await DataStore.save(
    Employee.copyOf(dbEmp, (updated) => {
      updated.qaDone = true;
      updated.isScreeningDone = isScreeningDone;
      updated.dailyTask = dailyTask;
      updated.scheduleData = {
        ...(dbEmp.scheduleData || {}),
        quesDate: moment().format("YYYY-MM-DD"),
        questResult: "1",
      };
    })
  );
  let logData = [];
  logData.push({
    schrID: emp.id,
    auditType: "PreScreeningQuestion",
    action: "Done",
    companyID: emp.companyID,
    userName: userName,
  });
  await addlogs(logData);
  return employee;
};

const zoneAssignMessage = async (emp, setting, programs, res, prevZone) => {
  const phone = emp.phoneNumber;
  const user = await getCurrentUser();
  const email = emp.email;
  const smsData = [];
  const prog = programs.find((f) => f.id === emp.programID);
  let message = "";
  if (setting && setting.isStartProgramVerbiage && setting.startProgramVerbiage) {
    let tests = [];
    let subject = prevZone ? "Zone Changed" : "Zone Assigned";
    if (prog?.testsData && prog.testsData.length > 0) {
      const todayTest = prog.testsData.find((f) => f.date === moment().format("YYYY-MM-DD"));
      if (todayTest) {
        const prevEvent = todayTest.testTypes;
        tests = [...prevEvent];
      }
    }
    let reminderTest = "";
    reminderTest = tests.map((t) => TEST_TYPE_VALUE[t.value]).join(",");
    message = parseMessage(setting.startProgramVerbiage, emp, "", reminderTest, "", setting);
    smsData.push({
      phoneNumber: phone,
      email,
      message,
      subject: setting.startProgramSubject || subject,
      sendEmail: emp.sendEmail || 1,
      sendSMS: emp.sendSMS || 1,
      schrID: res?.id || emp.id,
      slug: prevZone ? "ChangeProgram" : "AssignProgram",
      userName: user.name,
      companyID: res?.companyID || emp.companyID,
      programName: emp.programName,
    });
  }

  await updateCallTimeLogs(smsData, setting.startProgramType);
};

const saveFileExternalStorage = async (phoneNumber, type, fileContent) => {
  try {
    // set the date format
    let fileName = `${phoneNumber}/`;
    if (type) fileName = `${fileName}${type}_#datePattern#_${moment().utc().format("YYYY-MM-DD HH:mm")}.png`;

    await Storage.put(fileName, fileContent, { bucket: "employee-external-tests" });
    return fileName;
  } catch (err) {
    console.log("Error in Image Uploading", err);
  }
  return "";
};

const newEmployee = async (emp, num, user) => {
  let seqNumber = num;
  let isUpdateCounter = false;
  let testTwo = [];
  let logDate = null;
  const [programs, setting, departments] = await Promise.all([
    getProgramSettings(),
    getCompanySetting(),
    getDepartments(),
  ]);
  if (!seqNumber) {
    seqNumber = setting.employeeCounter;
    isUpdateCounter = true;
    seqNumber++;
  }
  const employeeID = `SCHR-${setting.companyCode}-${seqNumber.toString().padStart(6, 0)}`;
  const isNewSignUp = await DataStore.query(Employee, (e) => e.phoneNumber("eq", emp.phoneNumber).isNew("eq", true));
  if (emp.programID) {
    [testTwo, logDate] = await getZoneLevelSetting(emp, programs, departments);
  }
  if (isNewSignUp.length > 0) {
    const notAssociatedWithCompany = isNewSignUp.filter((f) => !f.companyID);
    if (notAssociatedWithCompany.length > 0) {
      const dbEmp = notAssociatedWithCompany[0];
      await DataStore.save(
        Employee.copyOf(dbEmp, (updated) => {
          updated.schrID = employeeID;
          updated.jobTitle = emp.jobTitle;
          updated.region = emp.region;
          updated.location = emp.location;
          updated.department = emp.department;
          updated.empTZ = emp.empTZ;
          updated.unionNo = emp.unionNo;
          updated.localNo = emp.localNo;
          updated.isNew = false;
          updated.isQuarantine = 0;
          updated.onSet = 0;
          updated.programID = emp.programID;
          updated.testTwo = testTwo;
          updated.logDate = logDate;
          updated.qusAttempted = 0;
          updated.companyID = userCompanyID.get();
          updated.programName = emp.programName;
          updated.callTime = emp.callTime || null;
        })
      );
      if (isUpdateCounter) {
        await updateCompnayEmployeeNo(seqNumber);
      }
      return dbEmp;
    }
  }
  const employeeObject = {
    firstName: emp.firstName,
    lastName: emp.lastName,
    schrID: employeeID,
    email: emp.email,
    idNumber: emp.idNumber || `M${moment().valueOf().toString()}`,
    phoneNumber: emp.phoneNumber,
    jobTitle: emp.jobTitle,
    countryCode: emp.countryCode,
    region: emp.region,
    location: emp.location,
    street: toTitleCase(emp.street),
    street2: toTitleCase(emp.street2),
    sex: emp.gender || emp.sex,
    isSchedule: emp.isSchedule || 0,
    isVaccinated: emp.isVaccinated || false,
    isBooster: emp.isBooster || false,
    vaccinationFile: emp.vaccinationFile,
    boosterFile: emp.boosterFile,
    department: emp.department,
    country: toTitleCase(emp.country),
    state: capitalizeLetter(emp.state),
    city: toTitleCase(emp.city),
    callTime: emp.callTime || null,
    sendEmail: parseInt(emp.sendEmail),
    sendSMS: parseInt(emp.sendSMS),
    localNo: emp.localNo,
    unionNo: emp.unionNo,
    programName: emp.programName,
    programID: emp.programID,
    updatedByName: user.name,
    updatedBy: user.sub,
    zip: emp.zip,
    isNew: false,
    empTZ: emp.empTZ,
    companyID: userCompanyID.get(),
    isQuarantine: 0,
    onSet: 0,
    testTwo,
    logDate,
  };
  if (emp.isSchedule === STATUS.zoneAssign) {
    Object.assign(employeeObject, {
      scheduleData: { zoneAssignDate: moment().format("YYYY-MM-DD"), zoneAssignBy: user.name },
    });
  }

  if (emp.dob) {
    Object.assign(employeeObject, { dob: emp.dob });
  }

  const res = await DataStore.save(new Employee(employeeObject));
  if (emp.isSchedule === STATUS.zoneAssign && res) {
    await zoneAssignMessage(emp, setting, programs, res);
  }

  if (isUpdateCounter) {
    await updateCompnayEmployeeNo(seqNumber);
  }
  return res;
};

const updateEmpEmails = async (emps) => {
  for (let i = 0; i < emps.length; i++) {
    const emp = emps[i];
    const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
    const dbEmp = models[0];
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.email = `${dbEmp.firstName}${dbEmp.lastName}@yopmail.com`.toLocaleLowerCase();
      })
    );
  }
};

const updateEmployee = async (emp, user) => {
  const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
  let testTwo = null;
  let logDate = null;
  const [programs, setting, departments] = await Promise.all([
    getProgramSettings(),
    getCompanySetting(),
    getDepartments(),
  ]);
  const dbEmp = models[0];
  let profilePath = `${emp.id}_#datePattern#_${moment().utc().format("YYYY-MM-DD HH:mm")}.jpeg`;
  if (emp.programID && emp.programID !== dbEmp.programID) {
    [testTwo, logDate] = await getZoneLevelSetting(emp, programs, departments);
  }
  await DataStore.save(
    Employee.copyOf(dbEmp, (updated) => {
      updated.firstName = emp.firstName;
      updated.lastName = emp.lastName;
      updated.dob = emp.dob || null;
      updated.schrID = emp.schrID;
      updated.email = emp.email;
      updated.phoneNumber = emp.phoneNumber;
      updated.jobTitle = emp.jobTitle;
      updated.countryCode = emp.countryCode;
      updated.street = toTitleCase(emp.street);
      updated.street2 = toTitleCase(emp.street2);
      updated.region = emp.region;
      updated.sendEmail = parseInt(emp.sendEmail);
      updated.sendSMS = parseInt(emp.sendSMS);
      updated.location = emp.location;
      updated.sex = emp.gender;
      updated.isVaccinated = emp.isVaccinated;
      updated.isBooster = emp.isBooster;
      updated.department = emp.department;
      updated.idNumber = emp.idNumber || `M${moment().valueOf().toString()}`;
      updated.country = toTitleCase(emp.country);
      updated.city = toTitleCase(emp.city);
      updated.zip = emp.zip;
      updated.isSchedule = emp.isSchedule || 0;
      updated.empTZ = emp.empTZ;
      updated.unionNo = emp.unionNo;
      updated.programName = emp.programName;
      updated.programID = emp.programID;
      updated.testTwo = testTwo || dbEmp.testTwo;
      updated.logDate = logDate || dbEmp.logDate;
      updated.qusAttempted = 0;
      updated.localNo = emp.localNo;
      updated.updatedByName = user.name;
      updated.vaccinationFile = emp.vaccinationFile;
      updated.boosterFile = emp.boosterFile;
      updated.updatedBy = user.sub;
      updated.callTime = emp.callTime || null;
      updated.state = capitalizeLetter(emp.state);
      updated.picture = emp.profileImage instanceof Object ? profilePath : emp.picture;
      if (emp.isSchedule === STATUS.zoneAssign && emp.programID && emp.programID !== dbEmp.programID) {
        updated.scheduleData = {
          ...(dbEmp.scheduleData || {}),
          zoneAssignDate: moment().format("YYYY-MM-DD"),
          zoneAssignBy: user.name,
        };
      }
    })
  );

  // if (emp?.subID?.length > 0) {
  //   await updateEmployeeAuth(emp.subID ,emp.firstName, emp.lastName, emp.email);
  // } else {
  //   let searchFilter = `phone_number ^=\  "${emp.phoneNumber}\"`;
  //   const res = await fetchAllUsers(searchFilter);
  //   console.log("res", {res});
  //   const subId = res.Users[0]?.Attributes?.find((a) => a?.Name === "sub")?.Value || '';
  //   if (subId.length > 0) await updateEmployeeAuth(subId ,emp.firstName, emp.lastName, emp.email);
  // }

  if (emp.isSchedule === STATUS.zoneAssign && emp.programID && emp.programID !== dbEmp.programID) {
    await zoneAssignMessage(emp, setting, programs, null, dbEmp.programID);
  }
  if (emp.callTime && dbEmp.callTime !== emp.callTime) {
    const itemToUpdate = [];
    const reminderTest = parseZoneTestType(emp, programs);
    if (setting && setting.isCallTimeVerbige && setting.callTimeVerbiage) {
      itemToUpdate.push({
        companyID: setting.id,
        phoneNumber: emp.phoneNumber,
        email: emp.email,
        sendEmail: emp.sendEmail || 1,
        sendSMS: emp.sendSMS || 1,
        message: parseMessageCallTime(setting.callTimeVerbiage, emp, reminderTest, emp.callTime, setting),
        subject: setting.callTimeSubject || "Call Time Update",
        schrID: dbEmp.id,
        slug: "Call Time Update",
        userName: user.name,
      });
    }
    await updateCallTimeLogs(itemToUpdate, setting?.callTimeType);
  }
  try {
    if (emp.profileImage instanceof Object) {
      await Storage.put(`profile/${profilePath}`, emp.profileImage, { bucket: "medflow-images" });
    }
  } catch (err) {
    console.log("Error", err.message);
  }
};

// const updateEmployeeAuth = async (empUsername, empEmail, empFirstName, empLastName) => {
//   console.log({empUsername});
//   const path = "/updateUserBasicInfo";

//   const params = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
//     },
//     body: {
//       username: empUsername,
//       email: empEmail,
//       firstName: empFirstName,
//       lastName: empLastName
//     },
//   };

//   try {
//   const res = await API.post(apiName, path, params);
//   console.log('res', {res})
//   } catch (error) {
//     console.log("error: ", error);
//   }
// }

const fetchAllCrewMembers = async (groupName, NextToken) => {
  const path = "/listUsersInGroup";
  let users = [];
  const myInit = {
    queryStringParameters: {
      groupname: groupName,
      limit: 60,
      token: NextToken,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const models = await API.get(apiName, path, myInit);
  return models;
};

const isEmployeeScheduled = (schedule, deptTimeZone) => {
  try {
    let isNotify = false;
    const currentDate = moment().utc().add(TIME_ZONE_OFFSET[deptTimeZone], "minutes");
    const startDate = moment.utc(schedule.startDate, "YYYY-MM-DD").startOf("day");
    const endDate = moment.utc(schedule.endDate, "YYYY-MM-DD").endOf("day");
    const currentDay = currentDate.day();

    if (schedule.scheduleType === "untilcancel") {
      isNotify = schedule.days.includes(currentDay) && currentDate.isSameOrAfter(startDate);
    } else if (schedule.scheduleType === "everyday") {
      isNotify = currentDate.isBetween(startDate, endDate);
    } else if (schedule.scheduleType === "selecteddays") {
      isNotify = schedule.days.includes(currentDay) && currentDate.isBetween(startDate, endDate);
    }

    return isNotify;
  } catch (e) {
    console.log("Error while validating schedule");
    console.log(e);
  }
};

const getScheduleTask = (departments, schedules, dbEmp, scheduleLinked) => {
  let logDate = null;
  let dailyTask = [];
  let testOne = [];
  let testTwo = [];
  const dept = departments.find((d) => d.id === dbEmp.department);

  if (scheduleLinked && scheduleLinked.length > 0) {
    for (let i = 0; i < scheduleLinked.length; i++) {
      const schID = scheduleLinked[i];
      const empSchedule = schedules.find((s) => s.id === schID);
      if (empSchedule && isEmployeeScheduled(empSchedule, dept.deptTimeZone)) {
        // set user timezone
        const userDateTime = moment().utc().add(TIME_ZONE_OFFSET[dept.deptTimeZone], "minutes");
        logDate = `${userDateTime.format("YYYY-MM-DD")}T00:00:00Z`;
        const day = userDateTime.day();
        const daySchedule = empSchedule.dayOptions.filter((d) => d.id === day);

        if (daySchedule.length > 0) {
          const scheduleRecord = daySchedule[0];
          if (empSchedule.scheduleCategory === SCHEDULE_CATEGORY.TESTING) {
            testOne = scheduleRecord.testType
              .filter((d) => d.location.toLowerCase() === "at home")
              .map((d) => {
                return { ...d, date: userDateTime.format("YYYY-MM-DD"), id: uuidv4(), lucira: d.value === "Lucira" };
              });
            testTwo = scheduleRecord.testType
              .filter((d) => d.location.toLowerCase() === "on location")
              .map((d) => {
                return { ...d, date: userDateTime.format("YYYY-MM-DD"), id: uuidv4(), lucira: d.value === "Lucira" };
              });
          } else {
            dailyTask.push({
              scheduleRecord,
              isDone: false,
              scheduleCategory: "Question",
            });
          }
        }
      }
    }
  }

  return [logDate, dailyTask, testOne, testTwo];
};

const updateEmployeeLogDate = async (params, schedules, departments) => {
  let logDate = null;
  let testOne = null;
  let testTwo = null;
  let dailyTask = [];
  const models = await DataStore.query(Employee, (e) => e.id("eq", params.id));
  if (models.length === 0) return;
  const dbEmp = models[0];
  if (params.isSchedule === 1) {
    [logDate, dailyTask, testOne, testTwo] = getScheduleTask(departments, schedules, dbEmp, params.scheduleLinked);
  }

  await DataStore.save(
    Employee.copyOf(dbEmp, (updated) => {
      updated.questionID = params.groupname ? params.groupname.value : null;
      updated.groupType = params.assigngrouptype.value;
      updated.groupName = params.assigngrouptype.label;
      updated.scheduleID = params.scheduleID;
      updated.programName = params.programName;
      updated.isSchedule = params.isSchedule;
      updated.testDone = false;
      updated.qaDone = false;
      updated.checkIn = null;
      updated.scheduleLinked = params.scheduleLinked;
      updated.logDate = logDate;
      updated.testOne = testOne;
      updated.testTwo = testTwo;
      updated.dailyTask = dailyTask.length === 0 ? null : dailyTask;
    })
  );
};

const updateEmployeeProgramState = async (ids, programStatus, params, userName) => {
  if (ids.length === 0) return;
  const [schedules, departments] = await Promise.all([getSchedules(), getDepartments()]);
  const ttl = ids.length;
  const setting = await getCompanySetting();
  const smsData = [];

  for (let i = 0; i < ttl; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", ids[i].id));
    if (models.length === 0) continue;
    const dbEmp = models[0];
    const emp = ids[i];
    let logDate = null;
    let dailyTask = [];
    let testOne = null;
    let testTwo = null;
    if (programStatus === 1 && schedules) {
      [logDate, dailyTask, testOne, testTwo] = getScheduleTask(
        departments,
        schedules,
        dbEmp,
        params ? params.scheduleLinked : dbEmp.scheduleLinked
      );
    }
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        if (params) {
          updated.questionID = params.groupname ? params.groupname.value : null;
          updated.groupType = params.assigngrouptype.value;
          updated.groupName = params.assigngrouptype.label;
          updated.scheduleID = params.scheduleID;
          updated.programName = params.programName;
          updated.programID = params.programID;
          updated.qusAttempted = 0;
          updated.scheduleLinked = params.scheduleLinked;
        }
        updated.isSchedule = programStatus;
        updated.logDate = logDate;
        updated.dailyTask = dailyTask.length === 0 ? null : dailyTask;
        updated.isScreeningDone = false;
        updated.qaDone = false;
        updated.testDone = false;
        updated.onBoardingTesting = null;
        updated.testOne = testOne;
        updated.testTwo = testTwo;
        updated.checkIn = null;
        updated.manualCheckIn = false;
        updated.manualCheckInReason = "";
        updated.manualCheckAdmin = "";
      })
    );
    const phone = dbEmp.phoneNumber;

    const email = dbEmp.email;

    if (programStatus === 1 && phone) {
      let message = "";
      let subject = "Program Started";
      if (setting && setting.isStartProgramVerbiage && setting.startProgramVerbiage) {
        let tests = [];
        if (testOne && testOne.length > 0) {
          tests = tests.concat(testOne.filter((t) => !t.isDone));
        }
        if (testTwo && testTwo.length > 0) {
          tests = tests.concat(testTwo.filter((t) => !t.isDone));
        }

        let reminderTest = "";
        reminderTest = tests.map((t) => TEST_TYPE_VALUE[t.value]).join(",");
        message = parseMessage(setting.startProgramVerbiage, emp, "", reminderTest, "", setting);
        smsData.push({
          phoneNumber: phone,
          email,
          message,
          sendEmail: dbEmp.sendEmail || 1,
          sendSMS: dbEmp.sendSMS || 1,
          subject: setting.startProgramSubject || subject,
          schrID: dbEmp.id,
          slug: "StartProgram",
          userName: userName || "N/A",
          companyID: dbEmp.companyID,
        });
      }
    }
  }

  await updateCallTimeLogs(smsData, setting.startProgramType);
};

const endDuplicateProgram = async (emp) => {
  const ttl = emp.length;
  for (let i = 0; i < ttl; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", emp[i].id));
    if (models.length === 0) continue;

    const dbEmp = models[0];
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.isSchedule = 2;
        updated.logDate = null;
        updated.dailyTask = [];
        updated.testOne = null;
        updated.testTwo = null;
        updated.onBoardingTesting = null;
        updated.manualCheckIn = false;
        updated.manualCheckInReason = "";
        updated.manualCheckAdmin = "";
        updated.qaDone = false;
        updated.testDone = false;
      })
    );
  }
};

const endProgram = async (paramIds) => {
  if (paramIds.length === 0) return;
  const testNowResult = paramIds.filter((f) => f.testNow);
  let ids = paramIds;
  const duplicateVal = [];
  if (testNowResult.length > 0) {
    testNowResult.forEach((f) => {
      const findVal = paramIds.find((i) => i.id === f.id.replace("%testNow", ""));
      if (findVal) {
        ids = ids.filter((f) => f.id.replace("%testNow", "") !== findVal.id);
        duplicateVal.push(findVal);
      }
    });
  }
  if (duplicateVal.length > 0) {
    endDuplicateProgram(duplicateVal);
  }
  const ttl = ids.length;
  const setting = await getCompanySetting();
  for (let i = 0; i < ttl; i++) {
    const empData = ids[i];
    const isTestNowEnd = ids[i].id.includes("testNow");
    const idNo = ids[i].id.replace("%testNow", "");
    const models = await DataStore.query(Employee, (e) => e.id("eq", idNo));

    if (models.length === 0) continue;

    const dbEmp = models[0];
    const isQADone = DAILY_QA_STATUS[empData.qaDone];
    const isTestDone = DAILY_QA_STATUS[empData.testDone];
    const logDate = empData.logDate;

    if (empData.logDate?.substring(0, 10) === moment().format("YYYY-MM-DD") && (isQADone || isTestDone)) {
      let logObject = { employeeID: dbEmp.id, logDate: empData.logDate };
      if (isTestNowEnd) {
        Object.assign(logObject, {
          qaDone: null,
          testDone: null,
          checkIn: empData.checkIn === "1" ? logDate : null,
          dailyTask: null,
          testOne: null,
          testTwo: null,
          onBoardingTesting: empData.onBoardingTesting,
        });
      } else {
        Object.assign(logObject, {
          qaDone: isQADone,
          testDone: isTestDone,
          checkIn: empData.checkIn === "1" ? logDate : null,
          dailyTask: empData.dailyTask,
          testOne: empData.testOne,
          testTwo: empData.testTwo,
          onBoardingTesting: empData.onBoardingTesting,
        });
      }
      if (empData.manualCheckIn) {
        Object.assign(logObject, {
          manualCheckIn: empData.manualCheckIn,
          manualCheckInReason: empData.manualCheckInReason,
          manualCheckAdmin: empData.manualCheckAdmin,
        });
      }
      await DataStore.save(new EmployeeDailyLog(logObject));
    }
    let testOne = dbEmp.testOne || [];
    let testTwo = dbEmp.testTwo || [];

    if (isTestNowEnd) {
      if (dbEmp.isSchedule === 1) {
        testOne = testOne.filter((t) => !t.testNow);
        testTwo = testTwo.filter((t) => !t.testNow);
      } else {
        testOne = [];
        testTwo = [];
      }
      await DataStore.save(
        Employee.copyOf(dbEmp, (updated) => {
          updated.onBoardingTesting = null;
          updated.testOne = testOne;
          updated.testTwo = testTwo;
        })
      );
    } else {
      let logDate = dbEmp.logDate;
      if (dbEmp.onBoardingTesting) {
        testOne = testOne.filter((t) => t.testNow);
        testTwo = testTwo.filter((t) => t.testNow);
      } else {
        logDate = null;
        testOne = [];
        testTwo = [];
      }
      await DataStore.save(
        Employee.copyOf(dbEmp, (updated) => {
          updated.isSchedule = 2;
          updated.logDate = logDate || null;
          updated.dailyTask = [];
          updated.testOne = testOne;
          updated.testTwo = testTwo;
          updated.manualCheckIn = false;
          updated.manualCheckInReason = "";
          updated.manualCheckAdmin = "";
          updated.qaDone = false;
          updated.testDone = false;
        })
      );
    }
  }
};

const updateEmployeeGroup = async (emps, params) => {
  for (let i = 0; i < emps.length; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", emps[i]));
    if (models.length === 0) continue;
    const dbEmp = models[0];

    const d = await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.questionID = params.groupname ? params.groupname.value : null;
        updated.groupType = params.assigngrouptype.value;
        updated.groupName = params.assigngrouptype.label;
        updated.scheduleID = params.scheduleID;
        updated.programName = params.programName;
        updated.programID = params.programID;
        updated.isSchedule = 2;
        updated.scheduleLinked = params.scheduleLinked;
        updated.logDate = null;
        updated.testOne = null;
        updated.testTwo = null;
        updated.dailyTask = null;
      })
    );
  }
};
const updateEmployeeGroupState = async (emps, params, departments, schedules, notification) => {
  const setting = await getCompanySetting();
  for (let i = 0; i < emps.length; i++) {
    let logDate = null;
    let testOne = null;
    let testTwo = null;
    let dailyTask = [];
    const models = await DataStore.query(Employee, (e) => e.id("eq", emps[i]));
    if (models.length === 0) return;
    const dbEmp = models[0];
    [logDate, dailyTask, testOne, testTwo] = getScheduleTask(departments, schedules, dbEmp, params.scheduleLinked);
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.questionID = params.groupname ? params.groupname.value : null;
        updated.groupType = params.assigngrouptype.value;
        updated.groupName = params.assigngrouptype.label;
        updated.scheduleID = params.scheduleID;
        updated.programName = params.programName;
        updated.isSchedule = 1;
        updated.scheduleLinked = params.scheduleLinked;
        updated.logDate = logDate;
        updated.testOne = testOne;
        updated.testTwo = testTwo;
        updated.dailyTask = dailyTask.length === 0 ? null : dailyTask;
      })
    );
    if (notification) {
      let departmentName = "";
      if (dbEmp.department) {
        const dptModal = await DataStore.query(Department, (e) => e.id("eq", dbEmp.department));
        departmentName = dptModal[0].name;
      }
      try {
        const phone = dbEmp.phoneNumber;
        const email = dbEmp.email;
        let message = "";
        if (setting && setting.isStartProgramVerbiage && setting.startProgramVerbiage) {
          let tests = [];
          if (testOne && testOne.length > 0) {
            tests = tests.concat(testOne.filter((t) => !t.isDone));
          }
          if (testTwo && testTwo.length > 0) {
            tests = tests.concat(testTwo.filter((t) => !t.isDone));
          }
          let reminderTest = "";
          reminderTest = tests.map((t) => t.value).join(",");
          if (reminderTest) {
            reminderTest = reminderTest.replace(/Other/g, "Rapid PCR");
          }
          message = parseMessage(setting.startProgramVerbiage, dbEmp, "", reminderTest, "", setting, departmentName);
          if (setting.startProgramType === "M") {
            await sendSMSNotification(phone, message);
          } else {
            await sendEmail([{ email: email, subject: "SafeCamp Notifcation", msg: message }]);
          }
        }
      } catch (err) {
        console.log("Error Message", err);
      }
    }
  }
};

const removeEmployeeGroup = async (ids, isZoneLevel, userName) => {
  const logData = [];
  const testNowIds = ids.filter((f) => f.includes("%testNow"));
  let empIds = [...ids];

  const duplicateVal = [];
  if (testNowIds.length > 0) {
    testNowIds.forEach((f) => {
      const findID = ids.find((i) => i === f.replace("%testNow", ""));
      if (findID) {
        empIds = empIds.filter((f) => f.replace("%testNow", "") !== findID);
        duplicateVal.push(findID);
      }
    });
  }
  if (isZoneLevel && duplicateVal.length === 0) {
    const ttl = empIds.length;
    for (let i = 0; i < ttl; i++) {
      const models = await DataStore.query(Employee, (e) => e.id("eq", empIds[i].replace("%testNow", "")));
      const dbEmp = models[0];
      await DataStore.save(
        Employee.copyOf(dbEmp, (updated) => {
          updated.questionID = null;
          updated.programName = null;
          updated.programID = null;
          updated.qusAttempted = 0;
          updated.isSchedule = STATUS.removeProgram;
          updated.dailyTask = [];
          updated.testOne = null;
          updated.testTwo = null;
          updated.checkIn = null;
          updated.manualCheckIn = false;
          updated.manualCheckInReason = "";
          updated.manualCheckAdmin = "";
          updated.qaDone = false;
          updated.testDone = false;
          updated.onBoardingTesting = null;
        })
      );
      if (!empIds[i].includes("%testNow")) {
        logData.push({
          id: dbEmp.id,
          slug: "RemoveProgram",
          userName: userName,
          companyID: dbEmp.companyID,
          programName: dbEmp.programName,
          phone_number: dbEmp.phoneNumber,
        });
      }
    }
  } else {
    if (duplicateVal.length > 0) empIds = [...duplicateVal];
    const ttl = empIds.length;
    for (let i = 0; i < ttl; i++) {
      const models = await DataStore.query(Employee, (e) => e.id("eq", empIds[i].replace("%testNow", "")));
      const dbEmp = models[0];
      await DataStore.save(
        Employee.copyOf(dbEmp, (updated) => {
          updated.questionID = null;
          updated.groupType = null;
          updated.groupName = null;
          updated.scheduleID = null;
          updated.programName = null;
          updated.programID = null;
          updated.qusAttempted = 0;
          updated.isSchedule = STATUS.removeProgram;
          updated.scheduleLinked = null;
          updated.logDate = null;
          updated.dailyTask = [];
          updated.testOne = null;
          updated.testTwo = null;
          updated.checkIn = null;
          updated.manualCheckIn = false;
          updated.manualCheckInReason = "";
          updated.manualCheckAdmin = "";
          updated.qaDone = false;
          updated.testDone = false;
          updated.onBoardingTesting = null;
        })
      );
      if (isZoneLevel) {
        logData.push({
          id: dbEmp.id,
          slug: "RemoveProgram",
          userName: userName,
          companyID: dbEmp.companyID,
          programName: dbEmp.programName,
          phone_number: dbEmp.phoneNumber,
        });
      }
    }
  }
  await addAuditLogs(logData);
};

const updateEmployeeZoneTestNow = async (ids, arr, userName) => {
  const empIds = ids;
  const [setting, programs, departments] = await Promise.all([
    getCompanySetting(),
    getProgramSettings(),
    getDepartments(),
  ]);
  const ttl = empIds.length;
  let testTwo = [];
  let logDate = null;

  const smsData = [];
  for (let i = 0; i < ttl; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", empIds[i].replace("%testNow", "")));

    if (models.length === 0) continue;
    const dbEmp = models[0];
    const emp = { ...dbEmp, onBoardingTesting: arr };

    [testTwo, logDate] = await getZoneLevelSetting(emp, programs, departments);

    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.onBoardingTesting = JSON.stringify(arr);
        updated.testTwo = testTwo;
        updated.logDate = logDate;
      })
    );
    if (arr[0].testNowVerbiage) {
      let departmentName = "";
      if (dbEmp.department) {
        const dptModal = await DataStore.query(Department, (e) => e.id("eq", dbEmp.department));
        departmentName = dptModal[0].name;
      }
      let reminderTest = arr.map((t) => TEST_TYPE_VALUE[t.value]).join(",");
      let message = parseMessage(arr[0].testNowVerbiage, dbEmp, "", reminderTest, "", setting, departmentName);
      // await sendEmailSMS(arr[0].testNowType, dbEmp, message, arr[0].testNowSubject || "Test Now");

      smsData.push({
        companyID: emp.companyID,
        phoneNumber: emp.phoneNumber,
        email: emp.email,
        attachments: arr[0].attachments,
        sendEmail: emp.sendEmail || 1,
        sendSMS: emp.sendSMS || 1,
        message,
        subject: arr[0].testNowSubject || "Test Now",
        schrID: emp.id,
        slug: "Message",
        userName,
      });

      // if (arr[0].testNowType === "M") {
      //   await sendSMSNotification(dbEmp.phoneNumber, message);
      // } else {
      //   await sendEmail([{ email: dbEmp.email, subject: arr[0].testNowSubject || "Test Now", msg: message }]);
      // }
    }
  }
  console.log({ smsData });

  await updateCallTimeLogs(smsData, arr[0].testNowType);
};

const sendEmailSMS = async (type, emp, message, subject) => {
  if ((emp.sendSMS == 1 || !emp.sendSMS) && type === "M") {
    await sendSMSNotification(emp.phoneNumber, message);
  } else if ((emp.sendEmail == 1 || !emp.sendEmail) && type === "E") {
    await sendEmail([{ email: emp.email, subject, msg: message }]);
  }
};

const updateEmployeeTest = async (empIds, arrData) => {
  const arr = arrData.map((data) => {
    const obj = { ...data };
    Object.assign(obj, { id: uuidv4(), lucira: false });
    if (obj.value === "Lucira") {
      Object.assign(obj, { lucira: true });
    }
    return obj;
  });
  const setting = await getCompanySetting();

  for (let i = 0; i < empIds.length; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", empIds[i]));

    if (models.length === 0) continue;

    const dbEmp = models[0];
    let testOne = dbEmp.testOne || [];
    let testTwo = dbEmp.testTwo || [];

    if (arr[0].overWrite === "Yes") {
      testOne = arr.filter((f) => f.location === "At Home");
      testTwo = arr.filter((f) => f.location === "On Location");
    } else {
      testOne = testOne.filter((f) => !f.testNow).concat(arr.filter((f) => f.location === "At Home"));
      testTwo = testTwo.filter((f) => !f.testNow).concat(arr.filter((f) => f.location === "On Location"));
    }

    const d = await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.onBoardingTesting = JSON.stringify(arr);
        if (arr[0].date === moment().format("YYYY-MM-DD")) {
          updated.logDate = dbEmp.logDate || `${arr[0].date}T00:00:00Z`;
        }
        updated.testOne = testOne;
        updated.testTwo = testTwo;
        updated.testDone = false;
      })
    );
    let departmentName = "";
    if (dbEmp.department) {
      const dptModal = await DataStore.query(Department, (e) => e.id("eq", dbEmp.department));
      departmentName = dptModal[0].name;
    }
    if (arr[0].testNowVerbiage) {
      let reminderTest = arr.map((t) => TEST_TYPE_VALUE[t.value]).join(",");
      let message = parseMessage(arr[0].testNowVerbiage, dbEmp, "", reminderTest, "", setting, departmentName);
      if (setting.testNowType === "M") {
        await sendSMSNotification(dbEmp.phoneNumber, message);
      } else {
        await sendEmail([{ email: dbEmp.email, subject: arr[0].testNowSubject || "Test Now", msg: message }]);
      }
    }
    // if (obj.testNowVerbiage) {
    //   let message = parseMessage(obj.testNowVerbiage, dbEmp, "", obj.label);
    //   await sendSMSNotification(dbEmp.phoneNumber, message);
    // }
  }
};
const addDefaultQuestions = async (emp) => {
  const defaultQuestions = [
    {
      groupName: "default",
      question: "Have you or anyone you have been near tested positive for COVID-19 in the past 72 hours",
    },
    {
      groupName: "default",
      question: "Have you felt any flu like systems such as a cough or running nose in the past 72 hours?",
    },
    {
      groupName: "default",
      question: "Are you currently awaiting results of a COVID-19 test?",
    },
    {
      groupName: "default",
      question:
        "Have you been told that you are suspected of having COVID-19 from a healthcare provider in the last 10 days?",
    },
  ];
  defaultQuestions.forEach(async (qs) => {
    await DataStore.save(
      new Question({
        groupName: qs.groupName,
        question: qs.question,
      })
    );
  });
};

const createSystemUser = async (newUser, obj) => {
  const attributePayload = {
    preferred_username: newUser.phoneNumber,
    email: newUser.email,
    phone_number: newUser.phoneNumber,
    "custom:role": "Employees",
    "custom:autoConfirm": "true",
    "custom:firstName": `${newUser.firstName}`,
    "custom:lastName": `${newUser.lastName}`,
    "custom:note": `${newUser.firstName} ${newUser.lastName}`,
    "custom:labID": newUser.id,
  };
  console.log("attributePayload", attributePayload);
  const signUp = await Auth.signUp({
    username: newUser.phoneNumber,
    password: newUser.password,
    attributes: attributePayload,
  });

  return signUp;
};

const createSchedule = async (obj) => {
  await DataStore.save(
    new Schedule({
      scheduleName: obj.scheduleName,
      scheduleType: obj.scheduleType,
      startDate: obj.startDate,
      endDate: obj.endDate,
      scheduleCategory: obj.scheduleCategory,
      dayOptions: obj.dayOptions,
      days: obj.days,
      companyID: userCompanyID.get(),
    })
  );
};

const updateSchedule = async (obj) => {
  const models = await DataStore.query(Schedule, (e) => e.id("eq", obj.id));
  const dbEmp = models[0];
  await DataStore.save(
    Schedule.copyOf(dbEmp, (updated) => {
      updated.scheduleName = obj.scheduleName;
      updated.scheduleType = obj.scheduleType;
      updated.startDate = obj.startDate;
      updated.endDate = obj.endDate;
      updated.scheduleCategory = obj.scheduleCategory;
      updated.dayOptions = obj.dayOptions;
      updated.days = obj.days;
    })
  );
};

const deleteSchedule = async (id) => {
  await DataStore.delete(Schedule, (obj) => obj.id("eq", id));
};

const deleteEmployee = async (id) => {
  const model = await DataStore.query(Employee, (e) => e.id("eq", id));
  const emp = model[0];
  if (emp.connectedTo && emp.connectedTo.length > 0) {
    for (const delRelation of emp.connectedTo) {
      const models = await DataStore.query(Employee, (o) => o.id("eq", delRelation.id));
      const dbEmp = models[0];
      let connected = [...(dbEmp.connectedTo || [])];
      connected = connected.filter((f) => f.id !== id);

      await DataStore.save(
        Employee.copyOf(dbEmp, (updated) => {
          updated.connectedTo = connected;
        })
      );
    }
  }
  await DataStore.delete(Employee, (obj) => obj.id("eq", id));
};

const getClients = async () => {
  const models = await DataStore.query(AppSetting);
  return models;
};

const addClient = async () => {
  await DataStore.save(
    new AppSetting({
      companyName: "Rick and Morty",
      contactName: "Taylor Whitehouse",
      contactNumber: "+19518975895",
      mdSiteID: "e80d2d61-b15b-46b4-9d41-8365cb3e6e48",
      programLevel: "zone",
      defaultTimeZone: "Pacific Standard Time",
      companyCode: "013",
      typeOfEnv: "prod",
      qusRetryAttempts: 2,
    })
  );
};

const updateMainContact = async (name, num, cid, email) => {
  const models = await DataStore.query(AppSetting, (o) => o.id("eq", cid));
  const dbEmp = models[0];
  await DataStore.save(
    AppSetting.copyOf(dbEmp, (updated) => {
      updated.contactName = name;
      updated.contactNumber = num;
      updated.contactEmail = email;
    })
  );
};

const updateTestingCord = async (name, num, cid, email) => {
  const models = await DataStore.query(AppSetting, (o) => o.id("eq", cid));
  const dbEmp = models[0];
  await DataStore.save(
    AppSetting.copyOf(dbEmp, (updated) => {
      updated.testingCordName = name;
      updated.testingCordNumber = num;
      updated.testingCordEmail = email;
    })
  );
};

const updateGeneralDptTime = async (data) => {
  const models = await DataStore.query(AppSetting, (o) => o.id("eq", data.id));
  const dbEmp = models[0];
  await DataStore.save(
    AppSetting.copyOf(dbEmp, (updated) => {
      updated.defaultCallTime = data.defaultCallTime;
      updated.defaultTimeZone = data.defaultTimeZone;
    })
  );
};

const updateAllDeptTime = async (departments, data, prevCallTime) => {
  const ttl = departments.length;
  let inConsistentCallTimes = [];
  for (let i = 0; i < ttl; i++) {
    const dpt = departments[i];
    let diff = 0;
    const models = await DataStore.query(Department, (e) => e.id("eq", dpt));
    const dbEmp = models[0];

    console.log({ dbEmp, prevCallTime });

    if (dbEmp.callTime && prevCallTime && dbEmp.callTime !== prevCallTime) {
      const deptTime = moment(`${moment().format("YYYY-MM-DD")} ${data.defaultCallTime}:00`);
      const arr = dbEmp.callTime.split(":");
      const empTime = moment().set({ hours: arr[0], minutes: arr[1], seconds: 0 });
      diff = Math.floor(moment.duration(empTime.diff(deptTime)).asMinutes());
      inConsistentCallTimes.push({
        ...dbEmp,
        diff: diff,
        newCallTime: data.defaultCallTime,
      });
      continue;
    }

    await DataStore.save(
      Department.copyOf(dbEmp, (updated) => {
        updated.callTime = data.defaultCallTime;
        updated.deptTimeZone = data.defaultTimeZone;
      })
    );
  }
  return {
    departments: inConsistentCallTimes,
    newCallTime: data.defaultCallTime,
  };
};

const updateAllEmployeesCallTime = async (Emps, data, userName, departments, sendType) => {
  const smsData = [];
  const updateEmps = [];
  let inConsistentCallTimes = [];
  const programs = await getProgramSettings();
  const ttl = Emps.length;
  for (let i = 0; i < ttl; i++) {
    let diff = 0;
    const prevCallTime = departments.find((f) => f.id === Emps[i].department)?.callTime;
    const emp = Emps[i];
    let departmentName = "";
    let dptModal = null;
    if (emp.department) {
      dptModal = await DataStore.query(Department, (e) => e.id("eq", emp.department));
      departmentName = dptModal[0].name;
    }
    if (emp.callTime && prevCallTime && emp.callTime !== prevCallTime && emp.callTime !== data.defaultCallTime) {
      const deptTime = moment(`${moment().format("YYYY-MM-DD")} ${data.defaultCallTime}:00`);
      const arr = emp.callTime.split(":");
      const empTime = moment().set({ hours: arr[0], minutes: arr[1], seconds: 0 });
      diff = Math.floor(moment.duration(empTime.diff(deptTime)).asMinutes());
      inConsistentCallTimes.push({
        ...emp,
        diff: diff,
        newCallTime: data.defaultCallTime,
      });
      continue;
    } else if (!prevCallTime) {
      inConsistentCallTimes.push({ ...emp, diff: 0, newCallTime: data.defaultCallTime });
      continue;
    }

    updateEmps.push({ id: emp.id, callTime: data.defaultCallTime });
    // const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
    // const dbEmp = models[0];
    // await DataStore.save(
    //   Employee.copyOf(dbEmp, (updated) => {
    //     updated.callTime = data.defaultCallTime;
    //   })
    // );

    const reminderTest = parseZoneTestType(emp, programs);

    const send = checkOnSetMsgSend(sendType, emp, dptModal, data.defaultCallTime, data.defaultTimeZone);

    smsData.push({
      companyID: data.id,
      phoneNumber: emp.phoneNumber,
      email: emp.email,
      sendEmail:
        emp.sendEmail === 1 && sendType === UPDATE_CALL_TIME_CHOICE.EXPECTED_ON_SET ? send : emp.sendEmail || 1,
      sendSMS: emp.sendSMS === 1 && sendType === UPDATE_CALL_TIME_CHOICE.EXPECTED_ON_SET ? send : emp.sendSMS || 1,
      message: parseMessageCallTime(
        sendType ? data["generalCallTimeReminder"].callTimeVerbiage : data.callTimeVerbiage,
        emp,
        reminderTest,
        data.defaultCallTime,
        data,
        departmentName
      ),
      subject: sendType ? data["generalCallTimeReminder"].callTimeSubject : data.callTimeSubject || "Call Time Update",
      schrID: emp.id,
      slug: "Call Time Update",
      userName: userName,
    });
  }
  await updateCallTimeLogs(smsData, sendType ? data["generalCallTimeReminder"].callTimeType : data.callTimeType);
  await updateEmpFile(updateEmps);
  return {
    employees: inConsistentCallTimes,
    newCallTime: data.defaultCallTime,
  };
};

const getCompanySetting = async () => {
  const models = await DataStore.query(AppSetting, (o) => o.id("eq", userCompanyID.get()));

  return models[0];
};

const getSchedules = async () => {
  const models = await DataStore.query(Schedule, (d) => d.companyID("eq", userCompanyID.get()));
  return models;
};

const getQuestions = async () => {
  const models = await DataStore.query(Question, (d) => d.companyID("eq", userCompanyID.get()));
  return models;
};

const createQuestionnaire = async (obj, user) => {
  await DataStore.save(
    new Question({
      groupName: obj.groupName,
      question: obj.question,
      companyID: userCompanyID.get(),
      createdByName: user?.name,
      updatedByName: user?.name,
    })
  );
};

const updateQuestionnaire = async (obj) => {
  const models = await DataStore.query(Question, (e) => e.id("eq", obj.id));
  const dbGrp = models[0];
  await DataStore.save(
    Question.copyOf(dbGrp, (updated) => {
      updated.groupName = obj.groupName;
      updated.question = obj.question;
    })
  );
};

const deleteQuestionnaire = async (id) => {
  await DataStore.delete(Question, (obj) => obj.id("eq", id));
};

const updateTestTypes = async (obj) => {
  const models = await DataStore.query(TestTypes, (e) => e.id("eq", obj.id));
  const dbTestType = models[0];
  await DataStore.save(
    TestTypes.copyOf(dbTestType, (updated) => {
      updated.totalTime = obj.totalTime;
      updated.firstAlert = obj.firstAlert;
      updated.secondAlert = obj.secondAlert;
    })
  );
};

const getTestTypes = async () => {
  const models = await DataStore.query(TestTypes);
  return models;
};
const newAppSettings = async (data) => {
  const models = await DataStore.query(AppSetting, (v) => v.id("eq", data.id));
  if (models.length === 0) return;
  await DataStore.save(
    AppSetting.copyOf(models[0], (updated) => {
      updated.isOnBoardVerbiage = data.isOnBoardVerbiage;
      updated.onBoardVerbiage = data.onBoardVerbiage;
      updated.onBoardSubject = data.onBoardSubject;
      updated.onBoardingAttachements = data.onBoardingAttachements ? JSON.stringify(data.onBoardingAttachements) : null;
      updated.isCreateEmployeeVerbiage = data.isCreateEmployeeVerbiage;
      updated.createEmplyeeVerbiage = data.createEmplyeeVerbiage;
      updated.createEmployeeSubject = data.createEmployeeSubject;
      updated.isStartProgramVerbiage = data.isStartProgramVerbiage;
      updated.startProgramVerbiage = data.startProgramVerbiage;
      updated.startProgramSubject = data.startProgramSubject;
      updated.startProgramAttachements = data.startProgramAttachements
        ? JSON.stringify(data.startProgramAttachements)
        : null;
      updated.isDailyTextVerbiage = data.isDailyTextVerbiage;
      updated.DailyTextVerbiage = data.DailyTextVerbiage;
      updated.dailyTextSubject = data.dailyTextSubject;
      updated.dailyTextAttachements = data.dailyTextAttachements ? JSON.stringify(data.dailyTextAttachements) : null;
      updated.isDailyReminderTwoVerbiage = data.isDailyReminderTwoVerbiage;
      updated.dailyReminderTwoVerbiage = data.dailyReminderTwoVerbiage;
      updated.dailyReminderSubject = data.dailyReminderSubject;
      updated.dailyReminderAttachements = data.dailyReminderAttachements
        ? JSON.stringify(data.dailyReminderAttachements)
        : null;
      updated.isTestingReminderOneVerbiage = data.isTestingReminderOneVerbiage;
      updated.testingReminderOneVerbiage = data.testingReminderOneVerbiage;
      updated.testingReminderSubject = data.testingReminderSubject;
      updated.testingReminderAttachements = data.testingReminderAttachements
        ? JSON.stringify(data.testingReminderAttachements)
        : null;
      updated.isTestingReminderTwoVerbiage = data.isTestingReminderTwoVerbiage;
      updated.testingReminderTwoVerbiage = data.testingReminderTwoVerbiage;
      updated.testingReminderTwoSubject = data.testingReminderTwoSubject;
      updated.testingReminderTwoAttachements = data.testingReminderTwoAttachements
        ? JSON.stringify(data.testingReminderTwoAttachements)
        : null;
      updated.isTestNowVerbiage = data.isTestNowVerbiage;
      updated.testNowVerbiage = data.testNowVerbiage;
      updated.testNowSubject = data.testNowSubject;
      updated.isCallTimeVerbige = data.isCallTimeVerbige;
      updated.callTimeVerbiage = data.callTimeVerbiage;
      updated.callTimeSubject = data.callTimeSubject;
      updated.callTimeAttachements = data.callTimeAttachements ? JSON.stringify(data.callTimeAttachements) : null;
      updated.onBoardType = data.onBoardType;
      updated.startProgramType = data.startProgramType;
      updated.dailyTextType = data.dailyTextType;
      updated.dailyReminderTwoType = data.dailyReminderTwoType;
      updated.testingReminderOneType = data.testingReminderOneType;
      updated.testingReminderTwoType = data.testingReminderTwoType;
      updated.callTimeType = data.callTimeType;
      updated.testNowType = data.testNowType;
      updated.isDriveOnAccessVerbiage = data.isDriveOnAccessVerbiage;
      updated.driveOnAccessVerbiage = data.driveOnAccessVerbiage;
      updated.driveOnAccessType = data.driveOnAccessType;
      updated.driveOnAccessSubject = data.driveOnAccessSubject;
      updated.driveOnAccessAttachements = data.driveOnAccessAttachements
        ? JSON.stringify(data.driveOnAccessAttachements)
        : null;
      updated.generalCallTimeReminder = data.generalCallTimeReminder
        ? JSON.stringify(data.generalCallTimeReminder)
        : null;
      updated.callTimeReminder = data.callTimeReminder ? JSON.stringify(data.callTimeReminder) : null;
    })
  );
};
const getDepartments = async () => {
  const models = await DataStore.query(Department, (d) => d.companyID("eq", userCompanyID.get()), {
    sort: (emp) => emp.name(SortDirection.ASCENDING),
  });
  return models;
};

const getDepartmentById = async (id) => {
  const models = await DataStore.query(Department, (d) => d.id("eq", id));
  return models[0];
};

const getRegions = async () => {
  const models = await DataStore.query(Regions, (d) => d.companyID("eq", userCompanyID.get()));
  return models;
};

const getLocations = async () => {
  const models = await DataStore.query(Locations, (d) => d.companyID("eq", userCompanyID.get()));
  return models;
};
const getProgramSettings = async () => {
  const models = await DataStore.query(Programs, (d) => d.companyID("eq", userCompanyID.get()));
  return models;
};
const newLocations = async (loc, user) => {
  const compnayID = userCompanyID.get();
  const models = await DataStore.query(Locations, (d) => d.name("eq", loc).companyID("eq", compnayID));
  if (models.length > 0) return false;
  await DataStore.save(
    new Locations({
      name: loc,
      companyID: compnayID,
      createdByName: user.name,
      updatedByName: user.name,
    })
  );
  return true;
};

const newZone = async (name, user) => {
  const compnayID = userCompanyID.get();
  const models = await DataStore.query(Programs, (d) => d.name("eq", name).companyID("eq", compnayID));
  if (models.length > 0) return models[0];
  const res = await DataStore.save(
    new Programs({
      name: name,
      color: "#000000",
      companyID: compnayID,
      createdByName: user.name,
      updatedByName: user.name,
      testsData: [],
      scheduleData: [],
    })
  );
  return res;
};

const newProgramSettings = async (prog, user) => {
  const compnayID = userCompanyID.get();
  const models = await DataStore.query(Programs, (d) => d.name("eq", prog.name).companyID("eq", compnayID));
  if (models.length > 0) return false;
  const res = await DataStore.save(
    new Programs({
      name: prog.name,
      color: prog.color,
      testValidity: null,
      testSchedule: prog.testSchedule || null,
      testsData: prog.testsData || null,
      questionsData: prog.questionsData,
      questionsId: prog.questionsId,
      scheduleID: prog.scheduleID,
      scheduleType: "month",
      companyID: compnayID,
      createdByName: user.name,
      updatedByName: user.name,
    })
  );
  console.log({ res });
  return res;
};
const newRegions = async (name, user) => {
  const compnayID = userCompanyID.get();
  const userName = loggedInUser.get();
  const models = await DataStore.query(Regions, (d) => d.name("eq", name).companyID("eq", compnayID));
  if (models.length > 0) return false;
  const res = await DataStore.save(
    new Regions({
      name: name,
      companyID: compnayID,
      createdByName: user.name,
      updatedByName: user.name,
    })
  );

  return true;
};
const newDepartment = async (dpt, user) => {
  const compnayID = userCompanyID.get();
  const models = await DataStore.query(Department, (d) => d.name("eq", dpt.name).companyID("eq", compnayID));
  if (models.length > 0) return false;
  const updateObject = {
    name: dpt.name,
    callTime: dpt.callTime,
    reminderOne: dpt.reminderOne,
    reminderTwo: dpt.reminderTwo,
    deptTimeZone: dpt.deptTimeZone,
    reminderOneDirection: dpt.reminderOneDirection,
    reminderTwoDirection: dpt.reminderTwoDirection,
    companyID: compnayID,
    createdByName: user.name,
    updatedByName: user.name,
  };

  if (dpt.contact_name) Object.assign(updateObject, { contact_name: dpt.contact_name });
  if (dpt.phone_number) Object.assign(updateObject, { phone_number: dpt.phone_number });
  if (dpt.contact_email) Object.assign(updateObject, { contact_email: dpt.contact_email });

  await DataStore.save(new Department(updateObject));

  return true;
};

// const updateDepartmentData = async (dpt) => {
//   const models = await DataStore.query(Department, (e) => e.id("eq", dpt.id));
//   console.log("Update Dpt", dpt);
//   const dbEmp = models[0];
//   const prevCallTime = dbEmp.callTime;
//   const [setting, programs] = await Promise.all([getCompanySetting(), getProgramSettings()]);
//   if (models.length === 0) return false;
//   await DataStore.save(
//     Department.copyOf(dbEmp, (updated) => {
//       updated.name = dpt.name;
//       updated.callTime = dpt.callTime;
//       updated.reminderOne = dpt.reminderOne;
//       updated.reminderTwo = dpt.reminderTwo;
//       updated.deptTimeZone = dpt.deptTimeZone;
//       updated.reminderOneDirection = dpt.reminderOneDirection;
//       updated.reminderTwoDirection = dpt.reminderTwoDirection;
//       updated.contact_name = dpt.contact_name;
//       updated.phone_number = dpt.phone_number;
//       updated.contact_email = dpt.contact_email;
//     })
//   );

//   let inConsistentCallTimes = [];
//   let itemToUpdate = [];
//   const employees = (await DataStore.query(Employee, (e) => e.department("eq", dpt.id))) || [];

//   const ttl = employees.length;
//   console.log("Employees", employees);

//   for (let i = 0; i < ttl; i++) {
//     let diff = 0;
//     const emp = employees[i];

//     if (emp.callTime && prevCallTime && emp.callTime !== prevCallTime && emp.callTime !== dpt.callTime) {
//       const deptTime = moment(`${moment().format("YYYY-MM-DD")} ${prevCallTime}:00`);
//       const arr = emp.callTime.split(":");
//       const empTime = moment().set({ hours: arr[0], minutes: arr[1], seconds: 0 });
//       diff = Math.floor(moment.duration(empTime.diff(deptTime)).asMinutes());
//       inConsistentCallTimes.push({ ...emp, diff: diff });
//       continue;
//     } else if (!prevCallTime) {
//       inConsistentCallTimes.push({ ...emp, diff: 0 });
//       continue;
//     }

//     await DataStore.save(
//       Employee.copyOf(employees[i], (updated) => {
//         updated.callTime = dpt.callTime;
//       })
//     );
//     const reminderTest = parseZoneTestType(emp, programs);

//     if (setting && setting.isCallTimeVerbige && setting.callTimeVerbiage) {
//       itemToUpdate.push({
//         companyID: setting.id,
//         phoneNumber: emp.phoneNumber,
//         email: emp.email,
//         sendEmail: emp.sendEmail || 1,
//         sendSMS: emp.sendSMS || 1,
//         message: parseMessageCallTime(setting.callTimeVerbiage, emp, reminderTest, dpt.callTime),
//         subject: setting.callTimeSubject || "Call Time Update",
//         schrID: emp.id,
//         slug: "Call Time Update",
//         userName: userName,
//       });
//     }
//   }

//   await updateCallTimeLogs(itemToUpdate, setting?.callTimeType);

//   return {
//     employees: inConsistentCallTimes,
//     callTime: `${moment().format("YYYY-MM-DD")} ${prevCallTime}`,
//     newCallTime: dpt.callTime,
//   };
// };

const addDepartment = async (dpt, user) => {
  const compnayID = userCompanyID.get();
  const models = await DataStore.query(Department, (d) => d.name("eq", dpt.name).companyID("eq", compnayID));
  if (models.length > 0) return models[0].id;
  const data = await DataStore.save(
    new Department({
      name: dpt.name,
      callTime: dpt.callTime || null,
      deptTimeZone: dpt.deptTimeZone,
      companyID: compnayID,
      createdByName: user.name,
      updatedByName: user.name,
    })
  );
  return data.id;
};

const isDepartmentExists = async (name) => {
  const models = await DataStore.query(Department, (d) => d.name("eq", name).companyID("eq", userCompanyID.get()));

  return models.length > 1;
};

const updateDepartment = async (dpt, userName, importDpt) => {
  const models = await DataStore.query(Department, (e) => e.id("eq", dpt.id));
  const dbEmp = models[0];
  const prevCallTime = dbEmp.callTime;
  const [setting, programs] = await Promise.all([getCompanySetting(), getProgramSettings()]);

  await DataStore.save(
    Department.copyOf(dbEmp, (updated) => {
      updated.name = dpt.name;
      updated.callTime = dpt.callTime;
      updated.reminderOne = dpt.reminderOne;
      updated.reminderTwo = dpt.reminderTwo;
      updated.deptTimeZone = dpt.deptTimeZone;
      updated.reminderOneDirection = dpt.reminderOneDirection;
      updated.reminderTwoDirection = dpt.reminderTwoDirection;
      if (dpt.contact_name) updated.contact_name = dpt.contact_name;
      if (dpt.phone_number) updated.phone_number = dpt.phone_number;
      if (dpt.contact_email) updated.contact_email = dpt.contact_email;
    })
  );
  let employees = [];
  let inConsistentCallTimes = [];
  let itemToUpdate = [];
  if (dpt.updateOption && !importDpt) {
    switch (dpt.updateOption) {
      case "active":
        employees = await DataStore.query(Employee, (e) => e.department("eq", dpt.id).isSchedule("eq", 3));
        break;
      case "inactive":
        employees = await DataStore.query(Employee, (e) => e.department("eq", dpt.id).isSchedule("ne", 3));
        break;
      default:
        employees = await DataStore.query(Employee, (e) => e.department("eq", dpt.id));
    }
  }
  //  else {
  //   employees = await DataStore.query(Employee, (e) => e.department("eq", dpt.id));
  // }
  const ttl = employees.length;
  for (let i = 0; i < ttl; i++) {
    let diff = 0;
    const emp = employees[i];

    if (emp.callTime && prevCallTime && emp.callTime !== prevCallTime && emp.callTime !== dpt.callTime) {
      const deptTime = moment(`${moment().format("YYYY-MM-DD")} ${dpt.callTime}:00`);
      const arr = emp.callTime.split(":");
      const empTime = moment().set({ hours: arr[0], minutes: arr[1], seconds: 0 });
      diff = Math.floor(moment.duration(empTime.diff(deptTime)).asMinutes());
      inConsistentCallTimes.push({ ...emp, diff: diff, newCallTime: dpt.callTime });
      continue;
    } else if (!prevCallTime) {
      inConsistentCallTimes.push({ ...emp, diff: 0, newCallTime: dpt.callTime });
      continue;
    }

    await DataStore.save(
      Employee.copyOf(employees[i], (updated) => {
        updated.callTime = dpt.callTime;
      })
    );
    const reminderTest = parseZoneTestType(emp, programs);

    if (setting && setting.isCallTimeVerbige && setting.callTimeVerbiage) {
      itemToUpdate.push({
        companyID: setting.id,
        phoneNumber: emp.phoneNumber,
        email: emp.email,
        sendEmail: emp.sendEmail || 1,
        sendSMS: emp.sendSMS || 1,
        message: parseMessageCallTime(setting.callTimeVerbiage, emp, reminderTest, dpt.callTime, setting, dpt.name),
        subject: setting.callTimeSubject || "Call Time Update",
        schrID: emp.id,
        slug: "Call Time Update",
        userName: userName,
      });
    }
  }

  await updateCallTimeLogs(itemToUpdate, setting?.callTimeType);

  return {
    employees: inConsistentCallTimes,
    callTime: `${moment().format("YYYY-MM-DD")} ${prevCallTime}`,
    newCallTime: dpt.callTime,
  };
};

const updateDepartmentCallTime = async (departments, data, userName, sendType) => {
  const ttl = departments.length;
  let inConsistentCallTimes = [];
  const programs = await getProgramSettings();
  const smsData = [];
  const updateEmps = [];
  for (let i = 0; i < ttl; i++) {
    const dpt = departments[i];
    const models = await DataStore.query(Department, (e) => e.id("eq", dpt.id));
    const dbDept = models[0];
    const prevCallTime = dbDept.callTime;
    await DataStore.save(
      Department.copyOf(dbDept, (updated) => {
        updated.callTime = dpt.callTime;
      })
    );
    let employees = await DataStore.query(Employee, (e) => e.department("eq", dpt.id));
    const ttl = employees.length;
    for (let i = 0; i < ttl; i++) {
      let diff = 0;
      const emp = employees[i];

      if (emp.callTime && prevCallTime && emp.callTime !== prevCallTime && emp.callTime !== dpt.callTime) {
        const deptTime = moment(`${moment().format("YYYY-MM-DD")} ${dpt.callTime}:00`);
        const arr = emp.callTime.split(":");
        const empTime = moment().set({ hours: arr[0], minutes: arr[1], seconds: 0 });
        diff = Math.floor(moment.duration(empTime.diff(deptTime)).asMinutes());
        inConsistentCallTimes.push({ ...emp, diff: diff, newCallTime: dpt.callTime });
        continue;
      } else if (!prevCallTime) {
        inConsistentCallTimes.push({ ...emp, diff: 0, newCallTime: dpt.callTime });
        continue;
      }

      updateEmps.push({ id: emp.id, callTime: dpt.callTime });

      // await DataStore.save(
      //   Employee.copyOf(employees[i], (updated) => {
      //     updated.callTime = dpt.callTime;
      //   })
      // );

      const reminderTest = parseZoneTestType(employees[i], programs);

      const send = checkOnSetMsgSend(sendType, emp, models, data.defaultCallTime, data.defaultTimeZone);

      smsData.push({
        companyID: data.id,
        phoneNumber: emp.phoneNumber,
        email: emp.email,
        sendEmail:
          emp.sendEmail === 1 && sendType === UPDATE_CALL_TIME_CHOICE.EXPECTED_ON_SET ? send : emp.sendEmail || 1,
        sendSMS: emp.sendSMS === 1 && sendType === UPDATE_CALL_TIME_CHOICE.EXPECTED_ON_SET ? send : emp.sendSMS || 1,
        message: parseMessageCallTime(
          sendType ? data["generalCallTimeReminder"].callTimeVerbiage : data.callTimeVerbiage,
          emp,
          reminderTest,
          dpt.callTime,
          data,
          dbDept.name
        ),
        subject: sendType
          ? data["generalCallTimeReminder"].callTimeSubject
          : data.callTimeSubject || "Call Time Update",
        schrID: emp.id,
        slug: "Call Time Update",
        userName: userName,
      });
    }
  }
  await updateEmpFile(updateEmps);
  await updateCallTimeLogs(smsData, sendType ? data["generalCallTimeReminder"].callTimeType : data.callTimeType);
  return {
    employees: inConsistentCallTimes,
    departments: departments,
  };
};

const customReSchedule = async (employees, date, setting) => {
  const ttl = employees.length;
  console.log("employees", employees, date);
  const isExpectedLevel = setting?.onSet === 1 ? true : false;
  for (let i = 0; i < ttl; i++) {
    const emp = employees[i];
    const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
    const empDb = models[0];
    const testData = { date: date, testTypes: emp.testTypes != "" ? emp.testTypes : null };
    let dates = [...(empDb.onSetDates?.dates || [])];
    let onSet = empDb.onSet;
    if (isExpectedLevel && !dates.includes(date)) {
      dates.push(date);
      if (date === moment().format("YYYY-MM-DD")) {
        onSet = 1;
      }
    }
    let customSchedule = [];
    if (emp.CustomSchedule && emp.CustomSchedule.length > 0) {
      const customTest = [...emp.CustomSchedule];
      const customTestIndex = customTest.findIndex((f) => f.date === date);
      if (customTestIndex !== -1) {
        customTest.splice(customTestIndex, 1, testData);
      } else {
        customTest.push(testData);
      }
      customSchedule.push(...customTest);
    } else {
      customSchedule.push(testData);
    }

    if (
      emp.zoneTests.length === emp.testTypes.length &&
      emp.zoneTests.every(
        (t) => emp.testTypes.findIndex((f) => f.value === t.value && f.location === t.location) !== -1
      )
    ) {
      customSchedule = customSchedule.filter((f) => f.date !== date);
    }
    console.log("customSchedule", customSchedule);

    let testList = [];
    let dailyTask = [];
    if (date === moment().format("YYYY-MM-DD")) {
      const dpt = await DataStore.query(Department, (e) => e.id("eq", emp.department));
      const zone = await DataStore.query(Programs, (e) => e.id("eq", emp.programID));
      let timeZone = setting.defaultTimeZone;
      if (dpt && dpt.length > 0) {
        timeZone = dpt.deptTimeZone;
      }
      const item = { ...emp, CustomSchedule: customSchedule };
      [testList, dailyTask] = await getZoneLevelCustomTestsName(item, zone[0], timeZone);
    }
    console.log("testList", testList, dailyTask);
    await DataStore.save(
      Employee.copyOf(empDb, (updated) => {
        updated.CustomSchedule = customSchedule != "" ? customSchedule : null;
        updated.onSetDates = { dates };
        if (date === moment().format("YYYY-MM-DD")) {
          updated.testTwo = testList;
          updated.dailyTask = dailyTask;
          updated.onSet = onSet;
        }
      })
    );
  }
};

const removeCustomSch = async (emp, date, setting) => {
  const isExpectedLevel = setting?.onSet === 1 ? true : false;
  const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
  const empDb = models[0];
  let customSchedule = [];
  if (empDb.CustomSchedule && empDb.CustomSchedule.length > 0) {
    const customTest = [...empDb.CustomSchedule];
    customSchedule = customTest.filter((f) => f.date !== date);
  }
  console.log("customSchedule", customSchedule);
  let dates = [...(empDb.onSetDates?.dates || [])];
  let onSet = empDb.onSet;
  if (isExpectedLevel && !dates.includes(date)) {
    dates.push(date);
    if (date === moment().format("YYYY-MM-DD")) {
      onSet = 1;
    }
  }
  let testList = [];
  let dailyTask = [];
  if (date === moment().format("YYYY-MM-DD")) {
    const dpt = await DataStore.query(Department, (e) => e.id("eq", emp.department));
    const zone = await DataStore.query(Programs, (e) => e.id("eq", emp.programID));
    let timeZone = setting.defaultTimeZone;
    if (dpt && dpt.length > 0) {
      timeZone = dpt.deptTimeZone;
    }
    const item = { ...emp, CustomSchedule: customSchedule };
    [testList, dailyTask] = await getZoneLevelCustomTestsName(item, zone[0], timeZone);
  }
  console.log("testList", testList, dailyTask);
  await DataStore.save(
    Employee.copyOf(empDb, (updated) => {
      updated.CustomSchedule = customSchedule != "" ? customSchedule : null;
      updated.onSetDates = { dates };
      if (date === moment().format("YYYY-MM-DD")) {
        updated.testTwo = testList;
        updated.dailyTask = dailyTask;
        updated.onSet = onSet;
      }
    })
  );
};

const updateCustomSchedule = async (employees, date, setting) => {
  const ttl = employees.length;
  console.log("employees", employees, date);

  const isExpectedLevel = setting?.onSet === 1 ? true : false;

  for (let i = 0; i < ttl; i++) {
    const emp = employees[i];
    const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
    const empDb = models[0];
    const testData = { date: date, testTypes: emp.testTypes != "" ? emp.testTypes : null };
    let customSchedule = [];
    let dates = [...(empDb.onSetDates?.dates || [])];
    let onSet = empDb.onSet;
    if (isExpectedLevel && !dates.includes(date)) {
      dates.push(date);
      if (date === moment().format("YYYY-MM-DD")) {
        onSet = 1;
      }
    }
    if (empDb.CustomSchedule && empDb.CustomSchedule.length > 0) {
      const customTest = [...empDb.CustomSchedule];
      const customTestIndex = customTest.findIndex((f) => f.date === date);
      if (customTestIndex !== -1) {
        customTest.splice(customTestIndex, 1, testData);
      } else {
        customTest.push(testData);
      }
      customSchedule.push(...customTest);
    } else {
      customSchedule.push(testData);
    }

    if (
      emp.zoneTests.length === emp.testTypes.length &&
      emp.zoneTests.every(
        (t) => emp.testTypes.findIndex((f) => f.value === t.value && f.location === t.location) !== -1
      )
    ) {
      customSchedule = customSchedule.filter((f) => f.date !== date);
    }
    console.log("customSchedule", customSchedule);

    let testList = [];
    let dailyTask = [];
    if (date === moment().format("YYYY-MM-DD")) {
      const dpt = await DataStore.query(Department, (e) => e.id("eq", emp.department));
      const zone = await DataStore.query(Programs, (e) => e.id("eq", emp.programID));
      let timeZone = setting.defaultTimeZone;
      if (dpt && dpt.length > 0) {
        timeZone = dpt.deptTimeZone;
      }
      const item = { ...emp, CustomSchedule: customSchedule };
      [testList, dailyTask] = await getZoneLevelCustomTestsName(item, zone[0], timeZone);
    }
    console.log("testList", testList, dailyTask);
    await DataStore.save(
      Employee.copyOf(empDb, (updated) => {
        updated.CustomSchedule = customSchedule != "" ? customSchedule : null;
        updated.onSetDates = { dates };
        if (date === moment().format("YYYY-MM-DD")) {
          updated.testTwo = testList;
          updated.dailyTask = dailyTask;
          updated.onSet = onSet;
        }
      })
    );
  }
};

const updateEmployeeCallTime = async (empIds, userName, sendType) => {
  const ttl = empIds.length;
  const [setting, programs] = await Promise.all([getCompanySetting(), getProgramSettings()]);
  const itemToUpdate = [];
  for (let i = 0; i < ttl; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", empIds[i].id));
    const dbEmp = models[0];
    let deptName = "";
    let dptModal = null;
    if (dbEmp.department) {
      dptModal = await DataStore.query(Department, (e) => e.id("eq", dbEmp.department));
      deptName = dptModal[0]?.name;
    }
    const reminderTest = parseZoneTestType(dbEmp, programs);

    const send = checkOnSetMsgSend(sendType, dbEmp, dptModal, setting.defaultCallTime, setting.defaultTimeZone);

    if (
      setting &&
      ((sendType &&
        setting["generalCallTimeReminder"].isCallTimeVerbige &&
        setting["generalCallTimeReminder"].callTimeVerbiage) ||
        (!sendType && setting.isCallTimeVerbige && setting.callTimeVerbiage))
    ) {
      itemToUpdate.push({
        companyID: setting.id,
        phoneNumber: dbEmp.phoneNumber,
        email: dbEmp.email,
        sendEmail:
          dbEmp.sendEmail === 1 && sendType === UPDATE_CALL_TIME_CHOICE.EXPECTED_ON_SET ? send : dbEmp.sendEmail || 1,
        sendSMS:
          dbEmp.sendSMS === 1 && sendType === UPDATE_CALL_TIME_CHOICE.EXPECTED_ON_SET ? send : dbEmp.sendSMS || 1,
        message: parseMessageCallTime(
          sendType ? setting["generalCallTimeReminder"].callTimeVerbiage : setting.callTimeVerbiage,
          dbEmp,
          reminderTest,
          empIds[i].callTime,
          setting,
          deptName
        ),
        subject: sendType
          ? setting["generalCallTimeReminder"].callTimeSubject
          : setting.callTimeSubject || "Call Time Update",
        schrID: dbEmp.id,
        slug: "Call Time Update",
        userName: userName,
      });
    }
  }
  await updateEmpFile(empIds);
  await updateCallTimeLogs(
    itemToUpdate,
    sendType ? setting["generalCallTimeReminder"]?.callTimeType : setting?.callTimeType
  );
  return true;
};

const checkOnSetMsgSend = (sendType, emp, dpt, calltime, timeZone) => {
  let send = 2;
  if (sendType && sendType === UPDATE_CALL_TIME_CHOICE.EXPECTED_ON_SET && dpt.length > 0) {
    const currentTime = moment().utc().add(TIME_ZONE_OFFSET[timeZone], "minutes").format("HH:mm");
    if (emp.onSetDates?.dates?.length > 0) {
      if (moment(calltime, "HH:mm").isBefore(moment(currentTime, "HH:mm"))) {
        send = emp.onSetDates.dates.findIndex((d) => d === moment().add(1, "d").format("YYYY-MM-DD")) !== -1 ? 1 : 2;
      } else {
        send = emp.onSetDates.dates.findIndex((d) => d === moment().format("YYYY-MM-DD")) !== -1 ? 1 : 2;
      }

      console.log("I am in dates", send);
    } else if (emp.onSetDates?.startDate && emp.onSetDates?.endDate) {
      send =
        getDaysArray(emp.onSetDates.startDate, emp.onSetDates.endDate).findIndex(
          (d) => d === moment().add(1, "d").format("YYYY-MM-DD")
        ) !== -1
          ? 1
          : 2;
      console.log("I am in start/end", send);
    }
  }

  return send;
};

const updateCallTimeLogs = async (list, callTimeType, fileName) => {
  if (list.length === 0) return;
  const fileType = fileName || "calltime";
  const type = callTimeType === "M" ? "sms" : "email";
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_${fileType}_${type}.csv`, jsonToCSV(list), {
    bucket: "hr-update-records",
  });
};

const updateEmpTestSchedule = async (data) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_updatetests.csv`, jsonToCSV(data), {
    bucket: "hr-update-records",
  });
};

const updateEmpFile = async (data) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_updateemployee.csv`, jsonToCSV(data), {
    bucket: "hr-update-records",
  });
};

const manualClearedEmployee = async (empIds, reason, userName) => {
  const ttl = empIds.length;
  for (let i = 0; i < ttl; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", empIds[i]));
    const dbEmp = models[0];
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.isScreeningDone = dbEmp.testDone || false;
        updated.qaDone = true;
        updated.manualCheckIn = true;
        updated.manualCheckInReason = reason;
        updated.manualCheckAdmin = userName;
        updated.scheduleData = {
          ...(dbEmp.scheduleData || {}),
          quesDate: moment().format("YYYY-MM-DD"),
          questResult: "M",
        };
      })
    );
  }

  return true;
};

const externalTestState = async (emp) => {
  const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id.replace("%testNow", "")));
  const dbEmp = models[0];
  await DataStore.save(
    Employee.copyOf(dbEmp, (updated) => {
      updated.onBoardingTesting = JSON.stringify(emp.onBoardingTesting);
      updated.testOne = JSON.stringify(emp.testOne);
      updated.testTwo = JSON.stringify(emp.testTwo);
      updated.externalTestFile = emp.externalTestFile;

      if ([...emp.testOne, ...emp.testTwo]?.every((e) => e.isDone)) {
        updated.testDone = true;
      }
    })
  );
  try {
    if (emp.externalTestImage instanceof Object) {
      await Storage.put(emp.externalTestFile, emp.externalTestImage, { bucket: "employee-external-tests" });
    }
  } catch (err) {
    console.log("Error", err);
  }
  return true;
};

const zoneLevelExternalTest = async (emp) => {
  try {
    const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id.replace("%testNow", "")));
    const dbEmp = models[0];

    const prevTestHistory = [...(emp.testHistory || [])];
    const newTestList = emp.testTwo.filter((t) => t.isNewResult);
    if (newTestList.length > 0) {
      const newTest = newTestList[0];
      const testPrevIndex = prevTestHistory.findIndex((t) => t.value === newTest.value);
      if (testPrevIndex !== -1) {
        prevTestHistory.splice(testPrevIndex, 1);
      }
      Object.assign(newTest, { date: moment().utc().toISOString() });
      prevTestHistory.push(newTest);
    }
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.testHistory = prevTestHistory;
        updated.onBoardingTesting = emp.onBoardingTesting;
      })
    );
    if (emp.externalTestImage instanceof Object) {
      await Storage.put(emp.externalTestFile, emp.externalTestImage, { bucket: "employee-external-tests" });
    }
  } catch (err) {
    console.log("Api Error", err);
  }
  return true;
};

const addAuditLogs = async (data) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_program.csv`, jsonToCSV(data), {
    bucket: "hr-update-records",
  });
};
const addlogs = async (data) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_audit_logs.csv`, jsonToCSV(data), {
    bucket: "hr-update-records",
  });
};
const updateLocation = async (loc) => {
  const models = await DataStore.query(Locations, (e) => e.id("eq", loc.id));
  const dbLoc = models[0];
  await DataStore.save(
    Locations.copyOf(dbLoc, (updated) => {
      updated.name = loc.name;
    })
  );
};

const updateProgramSettings = async (prog) => {
  const models = await DataStore.query(Programs, (e) => e.id("eq", prog.id));
  const dbProgram = models[0];
  await DataStore.save(
    Programs.copyOf(dbProgram, (updated) => {
      updated.name = prog.name;
      updated.color = prog.color;
      updated.testValidity = null;
      updated.scheduleType = "month";
      updated.testSchedule = prog.testSchedule || null;
      (updated.testsData = prog.testsData || null), (updated.scheduleID = prog.scheduleID);
    })
  );

  const isTodayTest = prog.testsData?.find((f) => f.date === moment().format("YYYY-MM-DD"));
  const prevTest = dbProgram.testsData?.find((f) => f.date === moment().format("YYYY-MM-DD"));
  let newTestTypes = [];
  let prevTestTypes = [];
  if (isTodayTest) {
    newTestTypes = isTodayTest.testTypes.map((m) => m.value);
  }
  if (prevTest) {
    prevTestTypes = prevTest.testTypes.map((m) => m.value);
  }
  return (
    dbProgram.name !== prog.name ||
    dbProgram.scheduleID !== prog.scheduleID ||
    newTestTypes.length !== prevTestTypes.length ||
    (newTestTypes.length > 0 && !newTestTypes.every((n) => prevTestTypes.includes(n)))
  );
};

const updateRegion = async (reg) => {
  const models = await DataStore.query(Regions, (e) => e.id("eq", reg.id));
  const dbReg = models[0];
  await DataStore.save(
    Locations.copyOf(dbReg, (updated) => {
      updated.name = reg.name;
    })
  );
};

const updateZonesTests = async (zones) => {
  if (zones.length === 0 || !zones) return;
  const ttl = zones.length;
  for (let i = 0; i < ttl; i++) {
    const zone = zones[i];
    const models = await DataStore.query(Programs, (e) => e.id("eq", zone.id));
    const dbProgram = models[0];
    await DataStore.save(
      Programs.copyOf(dbProgram, (updated) => {
        updated.testsData = zone.testsData;
        updated.testSchedule = zone.testSchedule;
      })
    );
  }
};
const quesSchedulesUpdate = async (schedules) => {
  if (schedules.length === 0 || !schedules) return;
  const ttl = schedules.length;
  for (let i = 0; i < ttl; i++) {
    const quesSch = schedules[i];
    const models = await DataStore.query(Schedule, (e) => e.id("eq", quesSch.id));
    const dbEmp = models[0];
    await DataStore.save(
      Schedule.copyOf(dbEmp, (updated) => {
        updated.dayOptions = quesSch.dayOptions;
      })
    );
  }
};

const deleteDepartment = async (id) => {
  const modal = await DataStore.query(Employee, (e) => e.department("eq", id));
  if (modal.length === 0) {
    await DataStore.delete(Department, (obj) => obj.id("eq", id));
  } else {
    return "This Department is Assign to Employees";
  }
};
const deleteLocation = async (id) => {
  await DataStore.delete(Locations, (obj) => obj.id("eq", id));
};
const deleteProgramSettings = async (id) => {
  await DataStore.delete(Programs, (obj) => obj.id("eq", id));
};
const deleteRegion = async (id) => {
  await DataStore.delete(Regions, (obj) => obj.id("eq", id));
};

const getEmployeeDailyLogs = async (id) => {
  return await DataStore.query(EmployeeDailyLog, (obj) => obj.employeeID("eq", id), {
    sort: (emp) => emp.logDate(SortDirection.DESCENDING),
  });
};

const getAllEmployeeDailyLogs = async () => {
  return await DataStore.query(EmployeeDailyLog, (e) => e.companyID("eq", userCompanyID.get()), {
    sort: (emp) => emp.logDate(SortDirection.DESCENDING),
  });
};

const getEmployeeQuestion = async (id, logDate) => {
  return (await DataStore.query(EmployeeQuestion)).filter(
    (f) =>
      f.employeeID === id &&
      moment(f.createdAt).startOf("day").format("YYYY-MM-DD") === moment(logDate).startOf("day").format("YYYY-MM-DD")
  );
};

const deleteDailyLog = async (id) => {
  await DataStore.delete(EmployeeDailyLog, (obj) => obj.id("eq", id));
};

const getEmployeeTestFromMD = async () => {
  const path = "/employeeTestFromMD";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      companyID: "ecfda7c0-8e21-43ce-a10a-cac36d9d36b4",
      flag: 0,
      phone_number: "5714788202",
    },
  };

  const counterData = await API.post(apiName, path, params);
  console.log("counterData", counterData);
};

const mergeEmployeeLogs = async (primaryId, deletedIds) => {
  const path = "/mergeEmployeeLogs";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      primaryId,
      deletedIds,
      companyID: userCompanyID.get(),
    },
  };

  const counterData = await API.post(apiName, path, params);
  console.log("counterData", counterData);
  return counterData;
};

const getPrevEnvData = async (data) => {
  // const path = "/sendEmailToCrew";
  const path = "/getTableData";

  const params = {
    body: {
      tablename: "Department-bhxmvj6obzdkncqyydtxv3pu7a-staging",
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error Email Send:", e);
  }
};
const getPreRegisterData = async (data, type, env) => {
  const path = "/getPreRegisterRecordNew";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      env: env,
      type,
      phone_number: data,
    },
  };

  const counterData = await API.post(apiName, path, params);
  return counterData.Items;
};
const getRegisterData = async (data) => {
  const path = "https://9dpj6yddw2.execute-api.eu-west-1.amazonaws.com/default/getPreFill";
  const counterData = await axios.post(path, data);
  return counterData.data.data;
};

const updateEmployeeDefault = async (id, name) => {
  const models = await DataStore.query(Employee, (e) => e.id("eq", id));
  const emp = models[0];
  await DataStore.save(
    Employee.copyOf(emp, (updated) => {
      updated.programName = name;
    })
  );
  return true;
};

const updateEmpAcknowledgeBy = async (emps, userName) => {
  if (!emps || emps.length === 0) return;
  let logData = [];
  for (let i = 0; i < emps.length; i++) {
    const emp = emps[i];
    const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
    const dbEmp = models[0];
    const testHistory = dbEmp.testHistory.map((t) => {
      return { ...t, isAcknowledged: true };
    });
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.testResult = null;
        updated.acknowledgeBy = userName;
        updated.testHistory = testHistory;
      })
    );
    logData.push({
      schrID: emp.id,
      auditType: "positiveAcknowledge",
      companyID: emp.companyID,
      userName: userName,
    });
  }

  await addlogs(logData);
};

const assignedDepartmentToEmp = async (ids, dpt, prog, zoneAssign, userName) => {
  if (!ids || ids.length === 0) return;
  const [setting, programs, departments] = await Promise.all([
    getCompanySetting(),
    getProgramSettings(),
    getDepartments(),
  ]);
  let logDate = null;
  let testTwo = [];
  const smsData = [];
  const empIds = ids;
  const ttl = empIds.length;

  for (let i = 0; i < ttl; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", ids[i]));
    const emp = models[0];

    const empData = { ...emp, programID: prog.id };

    [testTwo, logDate] = await getZoneLevelSetting(empData, programs, departments);
    await DataStore.save(
      Employee.copyOf(emp, (updated) => {
        updated.department = dpt.department;
        updated.callTime = dpt.callTime || null;
        updated.empTZ = dpt.empTZ;
        updated.programName = prog.name;
        updated.programID = prog.id;
        updated.qusAttempted = 0;
        updated.scheduleData = {
          ...(emp.scheduleData || {}),
          zoneAssignDate: moment().format("YYYY-MM-DD"),
          zoneAssignBy: userName,
        };
        updated.isSchedule = zoneAssign;
        updated.testTwo = testTwo;
        updated.logDate = logDate;
      })
    );
    const phone = emp.phoneNumber;

    const email = emp.email;
    if (zoneAssign === STATUS.zoneAssign && phone) {
      let message = "";
      if (setting && setting.isStartProgramVerbiage && setting.startProgramVerbiage) {
        let tests = [];
        let subject = emp.programID ? "Zone Changed" : "Zone Assigned";
        if (prog.testsData && prog.testsData.length > 0) {
          const todayTest = prog.testsData.find((f) => f.date === moment().format("YYYY-MM-DD"));
          if (todayTest) {
            const prevEvent = todayTest.testTypes;
            tests = [...prevEvent];
          }
        }
        let reminderTest = "";
        reminderTest = tests.map((t) => TEST_TYPE_VALUE[t.value]).join(",");
        message = parseMessage(setting.startProgramVerbiage, emp, "", reminderTest, prog.name, setting);
        let attachments = setting.startProgramAttachements
          ? setting.startProgramAttachements.map((f) => f.name).join(",")
          : "";
        smsData.push({
          schrID: emp.id,
          phoneNumber: phone,
          email,
          message,
          sendEmail: emp.sendEmail || 1,
          sendSMS: emp.sendSMS || 1,
          subject: setting.startProgramSubject || subject,
          slug: emp.programID ? "ChangeProgram" : "AssignProgram",
          userName: userName,
          attachments,
          programName: prog.name,
          companyID: emp.companyID,
        });
      }
    }
  }

  await updateCallTimeLogs(smsData, setting.startProgramType);
};

const updateEmpProgramName = async (ids, prog, zoneAssign, userName) => {
  if (!ids || ids.length === 0) return;

  const [setting, programs, departments] = await Promise.all([
    getCompanySetting(),
    getProgramSettings(),
    getDepartments(),
  ]);
  let logDate = null;
  let testTwo = [];
  const smsData = [];
  const empIds = ids;
  const ttl = empIds.length;
  for (let i = 0; i < ttl; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", empIds[i].replace("%testNow", "")));

    const emp = models[0];
    const empData = { ...emp, programID: prog.id };

    [testTwo, logDate] = await getZoneLevelSetting(empData, programs, departments);
    await DataStore.save(
      Employee.copyOf(emp, (updated) => {
        updated.programName = prog.name;
        updated.programID = prog.id;
        updated.qusAttempted = 0;
        updated.scheduleData = {
          ...(emp.scheduleData || {}),
          zoneAssignDate: moment().format("YYYY-MM-DD"),
          zoneAssignBy: userName,
        };
        updated.isSchedule = zoneAssign;
        updated.testTwo = testTwo;
        updated.logDate = logDate;
      })
    );
    const phone = emp.phoneNumber;

    const email = emp.email;
    if (zoneAssign === STATUS.zoneAssign && phone) {
      let message = "";
      if (setting && setting.isStartProgramVerbiage && setting.startProgramVerbiage) {
        let tests = [];
        let subject = emp.programID ? "Zone Changed" : "Zone Assigned";
        if (prog.testsData && prog.testsData.length > 0) {
          const todayTest = prog.testsData.find((f) => f.date === moment().format("YYYY-MM-DD"));
          if (todayTest) {
            const prevEvent = todayTest.testTypes;
            tests = [...prevEvent];
          }
        }
        let reminderTest = "";
        reminderTest = tests.map((t) => TEST_TYPE_VALUE[t.value]).join(",");
        message = parseMessage(setting.startProgramVerbiage, emp, "", reminderTest, prog.name, setting);
        let attachments = setting.startProgramAttachements
          ? setting.startProgramAttachements.map((f) => f.name).join(",")
          : "";
        smsData.push({
          schrID: emp.id,
          phoneNumber: phone,
          email,
          message,
          sendEmail: emp.sendEmail || 1,
          sendSMS: emp.sendSMS || 1,
          subject: setting.startProgramSubject || subject,
          slug: emp.programID ? "ChangeProgram" : "AssignProgram",
          userName: userName,
          attachments,
          programName: prog.name,
          companyID: emp.companyID,
        });
      }
    }
  }

  await updateCallTimeLogs(smsData, setting.startProgramType);
};

const updateEmployeeSub = async (id, subID) => {
  const models = await DataStore.query(Employee, (e) => e.id("eq", id));
  const emp = models[0];
  await DataStore.save(
    Employee.copyOf(emp, (updated) => {
      updated.subID = subID;
    })
  );
  return true;
};

const updateZoneNotes = async (Progs, note) => {
  if (Progs.length === 0 || !Progs) return;
  const ttl = Progs.length;
  for (let i = 0; i < ttl; i++) {
    const zone = Progs[i];
    if (!zone) continue;
    const models = await DataStore.query(Programs, (e) => e.id("eq", zone.id));
    const dbZone = models[0];
    await DataStore.save(
      Programs.copyOf(dbZone, (updated) => {
        updated.notes = note;
      })
    );
  }
};

const updateDeptNotes = async (Depts, note) => {
  if (Depts.length === 0 || !Depts) return;
  const ttl = Depts.length;
  for (let i = 0; i < ttl; i++) {
    const dpt = Depts[i];
    if (!dpt) continue;
    const models = await DataStore.query(Department, (e) => e.id("eq", dpt.id));
    const dbDept = models[0];
    await DataStore.save(
      Department.copyOf(dbDept, (updated) => {
        updated.notes = note;
      })
    );
  }
};

const updateEmpNotes = async (Emps, note) => {
  if (Emps.length === 0 || !Emps) return;
  const ttl = Emps.length;
  for (let i = 0; i < ttl; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", Emps[i]));
    const dbEmp = models[0];
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.notes = note ? note.replace(/\s/g, "") : note;
      })
    );
  }
};
const updateEmpProgram = async (Emps, programName) => {
  if (Emps.length === 0 || !Emps) return;
  const ttl = Emps.length;
  const [programs, departments] = await Promise.all([getProgramSettings(), getDepartments()]);
  for (let i = 0; i < ttl; i++) {
    const emp = Emps[i];
    if (!emp) continue;
    let logDate = null;
    let testTwo = null;
    const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
    const dbEmp = models[0];
    [testTwo, logDate] = await getZoneLevelSetting(emp, programs, departments);
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.programName = programName;
        updated.testTwo = testTwo;
        updated.logDate = logDate;
      })
    );
  }
};

const deleteSandboxData = async (id, name) => {
  const models = await DataStore.query(Employee, (e) => e.companyID("eq", "d7cbdc82-9d29-48bd-945b-a7dd3b192c2b"));
  models.forEach(async (e) => {
    await DataStore.delete(Employee, (obj) => obj.id("eq", e.id));
  });
  return true;
};

const updateEmployeeAddress = async () => {
  const models = await DataStore.query(Employee, (e) => e.id("eq", "57b4f60a-acec-4150-8224-49eb3d2e5c8e"));
  const emp = models[0];
  await DataStore.save(
    Employee.copyOf(emp, (updated) => {
      updated.street = "";
      updated.street2 = "";
      updated.city = "";
      updated.state = "";
      updated.zip = "";
      (updated.isVaccinated = false), (updated.idNumber = "");
    })
  );
  return true;
};

const updateEmployeeLogs = async (id, testDone, testTwo) => {
  const models = await DataStore.query(EmployeeDailyLog, (e) => e.id("eq", id));
  const emp = models[0];
  await DataStore.save(
    EmployeeDailyLog.copyOf(emp, (updated) => {
      (updated.testDone = testDone), (updated.testTwo = testTwo);
    })
  );
  return true;
};

const getEmployeeClients = async (username) => {
  console.log("[getEmployeeClients]");
  const path = "/getEmployeeClients";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      username: username,
    },
  };

  const counterData = await API.post(apiName, path, params);
  return counterData.Items.length > 0 ? counterData.Items[0] : {};
};

const getCompnayList = async () => {
  const path = "https://9dpj6yddw2.execute-api.eu-west-1.amazonaws.com/default/hrdefaults";
  const companies = await axios.post(path, { id: "87933844" });

  return companies.data.data;
};

const getEmplyeeCompany = async (username) => {
  const path = "https://9dpj6yddw2.execute-api.eu-west-1.amazonaws.com/default/MFHRPublicApi";

  const params = { headers: { "X-API-KEY": "I9KEyDzGIB9sCeovRZjsy9bNERk8duCYIFdMMs80" } };
  const body = {
    phoneNumber: username,
  };
  const company = await axios.post(path, body, params);
  return company?.data || [];
};

const employeeAuditTrail = async (id) => {
  console.log("[employeeAuditTrail]");
  const path = "/employeeAuditTrail";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: id,
    },
  };

  const counterData = await API.post(apiName, path, params);
  return counterData.tests;
};

const createHrEmployee = async (data) => {
  const path = "https://9dpj6yddw2.execute-api.eu-west-1.amazonaws.com/default/preregistration";
  const employeeData = await axios.put(path, data);
  return employeeData.data;
};

const parseZoneTestType = (emp, programs) => {
  const prog = programs.find((f) => f.id === emp.programID);
  let tests = [];
  if (prog?.testsData && prog.testsData.length > 0) {
    const todayTests = prog.testsData.find((f) => f.date === moment().format("YYYY-MM-DD"));
    if (todayTests) {
      const prevEvent = todayTests.testTypes;
      tests = [...prevEvent];
    }
  }
  if (emp.CustomSchedule && emp.CustomSchedule.length > 0) {
    const todaysCustomTest = emp.CustomSchedule.find((f) => f.date === moment().format("YYYY-MM-DD"));

    if (todaysCustomTest) {
      const prevEvent = todaysCustomTest.testTypes;
      tests = [...(prevEvent || [])];
    }
  }
  let reminderTest = "";
  reminderTest = tests.map((t) => TEST_TYPE_VALUE[t.value]).join(",");
  return reminderTest;
};

const sendNotificationToEmployee = async (employees, message, userName, messageType, subject, fileNames) => {
  const ttl = employees.length;

  const [programs, setting] = await Promise.all([getProgramSettings(), getCompanySetting()]);
  const smsData = [];
  for (let i = 0; i < ttl; i++) {
    const emp = employees[i];
    const reminderTest = parseZoneTestType(emp, programs);
    smsData.push({
      companyID: emp.companyID,
      phoneNumber: emp.phoneNumber,
      email: emp.email,
      attachments: fileNames.join(","),
      sendEmail: emp.sendEmail || 1,
      sendSMS: emp.sendSMS || 1,
      message: parseMessageCallTime(message, emp, reminderTest, emp.callTime, setting),
      subject: subject || "New Message",
      schrID: emp.id,
      slug: "Message",
      userName: userName,
    });
  }

  await updateCallTimeLogs(smsData, messageType);
};

const sendNotificationToDepartment = async (depts, message, userName, messageType, subject, fileNames) => {
  const setting = await getCompanySetting();
  const filteredData = messageType === "M" ? depts.filter((d) => d.phoneNumber) : depts.filter((d) => d.email);
  const ttl = filteredData.length;
  const smsData = [];
  for (let i = 0; i < ttl; i++) {
    const emp = filteredData[i];

    smsData.push({
      phoneNumber: emp.phoneNumber,
      email: emp.email,
      sendEmail: 1,
      sendSMS: 1,
      attachments: fileNames.join(","),
      message: parseMessageCallTime(message, emp, "", emp.callTime, setting),
      subject: subject || "Alert",
      userName: userName,
    });
  }
  await updateCallTimeLogs(smsData, messageType);
};

const sendQRCode = async (employees, message, userName, messageType, subject) => {
  const setting = await getCompanySetting();
  const ttl = employees.length;
  const smsData = [];
  for (let i = 0; i < ttl; i++) {
    const emp = employees[i];
    const color = emp.zoneColor || "#000000";
    smsData.push({
      id: emp.id,
      name: `${emp.firstName} ${emp.lastName}`,
      companyID: emp.companyID,
      phoneNumber: emp.phoneNumber,
      email: emp.email,
      sendEmail: emp.sendEmail || 1,
      sendSMS: emp.sendSMS || 1,
      message: parseMessageCallTime(message, emp, "", emp.callTime, setting),
      subject: subject || "QR Code",
      schrID: emp.id,
      slug: "QRCode",
      link: `${window.location.origin}/qrlink/${emp.id}:${color.substring(1)}`,
      userName: userName,
      color,
    });
  }

  await updateCallTimeLogs(smsData, messageType, "qrcode");
};
const getRelatedEmployee = async (connected) => {
  let arr = [];
  if (connected && connected.length > 0) {
    for (const con of connected) {
      const models = await DataStore.query(Employee, (o) => o.id("eq", con.id));
      let dbEmp = Object.assign({}, models[0]); // create a copy of the object that is extensible
      dbEmp["relation"] = con.relation;
      arr.push(dbEmp);
    }
  }
  return arr;
};

const getProxyContactPath = () => {
  return "https://9dpj6yddw2.execute-api.eu-west-1.amazonaws.com/default/contacttypes";
};

const getProxyContactData = async () => {
  const path = getProxyContactPath();
  const relations = await axios.get(path);
  return relations.data;
};

const updateProxyContactData = async (obj) => {
  const path = getProxyContactPath();
  const body = {
    id: obj.id,
    name: obj.name,
  };
  const res = await axios.put(path, body);
  return res;
};

const addProxyContactData = async (obj) => {
  const path = getProxyContactPath();
  const params = {
    name: obj.name,
  };
  const relations = await axios.post(path, params);
  return relations.data;
};

const deleteProxyContactData = async (id) => {
  const path = getProxyContactPath();
  const body = {
    data: {
      id: id,
    },
  };
  const res = await axios.delete(path, body);
  return res;
};

const updateRelations = async (id, relations, deleteRelations) => {
  const models = await DataStore.query(Employee, (o) => o.id("eq", id));
  const dbEmp = models[0];
  await DataStore.save(
    Employee.copyOf(dbEmp, (updated) => {
      updated.connectedTo = relations.length > 0 ? relations : null;
    })
  );

  for (const relation of relations) {
    const models = await DataStore.query(Employee, (o) => o.id("eq", relation.id));
    const dbEmp = models[0];
    let connected = [...(dbEmp.connectedTo || [])];
    if (connected && !connected?.some((obj) => obj.id === id)) {
      connected.push({ id, relation: relation.relation, relationType: "Parent" });
    } else {
      connected = [...connected].map((m) => {
        if (m.id === id) {
          return { id, relation: relation.relation, relationType: "Parent" };
        }
        return m;
      });
    }

    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.connectedTo = connected;
      })
    );
  }

  for (const delRelation of deleteRelations) {
    const models = await DataStore.query(Employee, (o) => o.id("eq", delRelation.id));
    const dbEmp = models[0];
    let connected = [...(dbEmp.connectedTo || [])];
    connected = connected.filter((f) => f.id !== id);

    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.connectedTo = connected;
      })
    );
  }
};

const updateConnectedTo = async (emp) => {
  const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
  const dbEmp = models[0];
  if (dbEmp) {
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.connectedTo = null;
      })
    );
  }
};

const getCompanyTestDoneList = async (startDate, endDate) => {
  let items = [];
  try {
    const path = "/getCompanyTestDoneList";
    const params = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
      body: {
        id: userCompanyID.get(),
        startDate,
        endDate,
      },
    };

    const counterData = await API.post(apiName, path, params);
    items = counterData;
  } catch (err) {
    console.log("err", err);
  }
  return items;
};

const updateQuarantine = async (id, qdays, pdays, date, userName) => {
  const models = await DataStore.query(Employee, (e) => e.id("eq", id));
  const dbEmp = models[0];
  if (dbEmp.isQuarantine === 1) {
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.quarantineDays = parseInt(qdays);
        updated.positiveDays = parseInt(pdays);
      })
    );
  } else {
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.quarantinedStart = date;
        updated.quarantineDays = parseInt(qdays);
        updated.positiveDays = parseInt(pdays);
        updated.isQuarantine = 1;
      })
    );
  }

  const logObj = {
    schrID: id,
    auditType: Zone_Audit_Types.Quarantine,
    companyID: dbEmp.companyID,
    userName: userName,
    quarantineDays: qdays,
    positiveDays: pdays,
    startDate: date,
  };

  await addlogs([logObj]);
  return true;
};

const endQuarantine = async (ids, userName) => {
  const ttl = ids.length;

  for (let i = 0; i < ttl; i++) {
    let id = ids[i];
    const models = await DataStore.query(Employee, (e) => e.id("eq", id));
    const dbEmp = models[0];
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.quarantinedEnd = moment().toISOString();
        updated.isQuarantine = 0;
      })
    );

    const logObj = {
      schrID: id,
      auditType: Zone_Audit_Types.Quarantine,
      companyID: dbEmp.companyID,
      userName: userName,
      quarantineDays: dbEmp.quarantineDays,
      positiveDays: dbEmp.positiveDays,
      endDate: moment().utc().toISOString(),
    };

    await addlogs([logObj]);
  }

  return true;
};

const updateExceptedOnSet = async (selectedUsersIds, userName, status, date) => {
  const logObj = [];
  const ttl = selectedUsersIds.length;
  const setting = await getCompanySetting();

  for (let i = 0; i < ttl; i++) {
    const userId = selectedUsersIds[i];
    const model = (await DataStore.query(Employee, (e) => e.id("eq", userId)))[0];
    let departmentName = "";
    if (model.department) {
      const dptModal = await DataStore.query(Department, (e) => e.id("eq", model.department));
      departmentName = dptModal[0]?.name;
    }
    await DataStore.save(
      Employee.copyOf(model, (updated) => {
        updated.onSet = status ? 1 : 0;
        updated.onSetDates = date || null;
      })
    );

    if (
      date?.dates?.length === 0 ||
      (model.onSetDates?.dates?.includes(currentDate()) && !date?.dates?.includes(currentDate()))
    ) {
      logObj.push({
        schrID: model.id,
        id: model.id,
        sendEmail: 0,
        sendSMS: 0,
        auditType: "ExpectedOnSet",
        action: "Removed",
        companyID: model.companyID,
        userName: userName,
        endDate: moment().utc().toISOString(),
        message: "",
        subject: "",
      });
    } else {
      logObj.push({
        id: model.id,
        schrID: model.id,
        auditType: "ExpectedOnSet",
        action: "Added",
        sendEmail: 0,
        sendSMS: 0,
        companyID: model.companyID,
        userName: userName,
        testDate: moment().utc().toISOString(),
        email: model.email,
        phoneNumber: model.phoneNumber,
        message: "",
        subject: "",
      });
    }
  }
  await updateCallTimeLogs(logObj, "M", "expectedonset");
};

const updateDriveAccessMembers = async (selectedUsersIds, userName, driveAccessibility, date) => {
  const logObj = [];
  const ttl = selectedUsersIds.length;
  const [programs, setting] = await Promise.all([getProgramSettings(), getCompanySetting()]);

  for (let i = 0; i < ttl; i++) {
    const userId = selectedUsersIds[i];
    const model = (await DataStore.query(Employee, (e) => e.id("eq", userId)))[0];
    let departmentName = "";
    if (model.department) {
      const dptModal = await DataStore.query(Department, (e) => e.id("eq", model.department));
      departmentName = dptModal[0]?.name;
    }
    await DataStore.save(
      Employee.copyOf(model, (updated) => {
        updated.driveOnAccess = driveAccessibility && date && date.startDate === moment().format("YYYY-MM-DD") ? 1 : 0;
        updated.driveOnAccessRange = date ? JSON.stringify(date) : null;
      })
    );
    const reminderTest = parseZoneTestType(model, programs);
    const message =
      setting?.isDriveOnAccessVerbiage && setting.driveOnAccessVerbiage
        ? parseMessageCallTime(
            setting.driveOnAccessVerbiage,
            model,
            reminderTest,
            model.callTime || "",
            setting,
            departmentName
          )
        : "";

    if (driveAccessibility) {
      let attachments = setting.driveOnAccessAttachements
        ? setting.driveOnAccessAttachements.map((f) => f.name).join(",")
        : "";

      logObj.push({
        id: model.id,
        schrID: model.id,
        auditType: "DriveOnAccess",
        action: "Acess Granted",
        sendEmail: model.sendEmail || 1,
        sendSMS: model.sendSMS || 1,
        companyID: model.companyID,
        userName: userName,
        testDate: moment().utc().toISOString(),
        email: model.email,
        phoneNumber: model.phoneNumber,
        dateRange: JSON.stringify(date),
        message: message,
        attachments,
        subject: setting.driveOnAccessSubject || "Drive on Access",
      });
    } else {
      logObj.push({
        schrID: model.id,
        id: model.id,
        sendEmail: model.sendEmail || 1,
        sendSMS: model.sendSMS || 1,
        auditType: "RemoveDriveAccess",
        action: "Acess Removed",
        companyID: model.companyID,
        userName: userName,
        endDate: moment().utc().toISOString(),
        message: message,
        subject: setting.driveOnAccessSubject || "Drive on Access",
      });
    }
  }
  await updateCallTimeLogs(logObj, setting.driveOnAccessType || "M", "drive");
};

const createCalendarSchedule = async (obj, user) => {
  const res = await DataStore.save(
    new Schedule({
      scheduleName: obj.scheduleName,
      scheduleType: obj.scheduleType,
      scheduleCategory: obj.scheduleCategory,
      dayOptions: obj.dayOptions,
      questionID: obj.questionID,
      companyID: userCompanyID.get(),
      createdByName: user.name,
      updatedByName: user.name,
    })
  );

  return res;
};

const getCalendarSchedules = async () => {
  const models = await DataStore.query(Schedule, (d) =>
    d.companyID("eq", userCompanyID.get()).scheduleType("eq", SCHEDULE_TYPES_VALUE.Calendar)
  );
  return models.map((m) => {
    return { ...m, value: m.id, label: m.scheduleName };
  });
};

const deleteCalendarSchedule = async (id) => {
  await DataStore.delete(Schedule, (obj) => obj.id("eq", id));
};

const updateCalendarSchedule = async (obj, user) => {
  const models = await DataStore.query(Schedule, (e) => e.id("eq", obj.id));
  const dbEmp = models[0];
  await DataStore.save(
    Schedule.copyOf(dbEmp, (updated) => {
      updated.scheduleName = obj.scheduleName;
      updated.scheduleType = obj.scheduleType;
      updated.scheduleCategory = obj.scheduleCategory;
      updated.dayOptions = obj.dayOptions;
      updated.questionID = obj.questionID;
      updated.updatedByName = user.name;
    })
  );
};

const updatePhoneNo = async (filteredUsers) => {
  const modifiedUser = filteredUsers.filter((f) => f.countryCode !== "+1");

  const ttl = modifiedUser.length;
  console.log("Total len", ttl);
  let j = 0;
  for (let i = 0; i < ttl; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", modifiedUser[i].id));
    if (
      models.length === 0 ||
      models[0].countryCode === "+267" ||
      models[0].countryCode === "+49" ||
      models[0].countryCode === "+44"
    )
      continue;

    const dbEmp = models[0];
    console.log(
      "Phone",
      dbEmp.phoneNumber.replace(/\D/g, "").length,
      dbEmp.firstName,
      dbEmp.lastName,
      dbEmp.email,
      dbEmp.countryCode,
      `+1${dbEmp.phoneNumber.replace(/\D/g, "")}`
    );
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.countryCode = "+1";
        updated.phoneNumber = `+1${dbEmp.phoneNumber.replace(/\D/g, "")}`;
      })
    );
    j++;
  }
  console.log("TotalProceess", j);
};

const getTestListFromPG = async (param) => {
  let statusNew = "";
  let statusPending = "";
  let test_type = [];
  let dob = "";
  if (param?.status?.toLowerCase() == "new") {
    statusNew = "pending";
    test_type = ["PCR"];
  }
  if (param?.status?.toLowerCase() == "pending") {
    statusPending = "pending";
    test_type = ["Lucira", "Molecular", "Antigen", "Rapid Flu", "Rapid PCR"];
  }
  if (param?.dob) {
    dob = param?.dob?.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
  }

  const path = "/getTestFromPG";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      params: dob
        ? { ...param, dob }
        : statusNew
        ? { ...param, status: statusNew, test_type }
        : statusPending
        ? { ...param, test_type }
        : param,
    },
  };

  const apiRes = await API.post(apiName, path, params);

  return apiRes.data;
};

const saveEmployeeQuestionAnswer = async (param) => {
  const path = "/employeeQuestion";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      userName: "Admin",
      employeeID: "d1115026-25ad-464c-b5f4-c7a960bf7522",
      answer: [
        { question: "Are you Covid Positive", answer: "No", isCorrect: 1 },
        { question: "Are you Vaccinated", answer: "Yes", isCorrect: 1 },
      ],
    },
  };

  const apiRes = await API.post(apiName, path, params);

  return apiRes.data;
};

export default {
  updatePhoneNo,
  endQuarantine,
  updateQuarantine,
  sendNotificationToDepartment,
  sendNotificationToEmployee,
  sendQRCode,
  updateCompnayEmployeeNo,
  deleteSandboxData,
  addAuditLogs,
  getEmployeeClients,
  getEmplyeeCompany,
  updateEmployeeQuestionList,
  updateEmployeeLogs,
  createEmployee,
  deleteEmployee,
  getCurrentUser,
  getAllUsers,
  signIn,
  fetchUsersInGroup,
  fetchAllUsers,
  fetchAllUsersInGroup,
  getProgramSettings,
  newZone,
  newProgramSettings,
  updateProgramSettings,
  changeUserRole,
  updateUserInfo,
  addUserClients,
  deleteUser,
  updateUserPassword,
  sendSMSNotification,
  sendEmail,
  sendEmailToCrew,
  deleteProgramSettings,
  updateEmpTestSchedule,
  deleteUserLinkFromEmployee,
  getEmployees,
  getEmployeeById,
  getlatestEmployee,
  getEmployeeQuestion,
  newEmployee,
  updateEmployee,
  updateEmployeeProgramState,
  updateEmployeeLogDate,
  updateEmployeeGroup,
  removeEmployeeGroup,
  addDefaultQuestions,
  getSchedules,
  getQuestions,
  createSchedule,
  updateRegion,
  updateSchedule,
  deleteSchedule,
  updateTestTypes,
  updateLocation,
  createQuestionnaire,
  updateRelations,
  getRelatedEmployee,
  fetchAllEmployees,
  confirmUserSignUp,
  updateQuestionnaire,
  deleteQuestionnaire,
  updateBlockOut,
  deleteLocation,
  deleteRegion,
  updateEmpNotes,
  updateZoneNotes,
  updateDeptNotes,
  endProgram,
  getTestTypes,
  getTestDetailFromMD,
  getClients,
  newLocations,
  newRegions,
  getDepartments,
  getDepartmentById,
  newDepartment,
  getPreRegisterData,
  updateGeneralDptTime,
  updateAllDeptTime,
  updateAllEmployeesCallTime,
  getLocations,
  newAppSettings,
  updateDepartment,
  deleteDepartment,
  isDepartmentExists,
  getEmployeeDailyLogs,
  getAllEmployeeDailyLogs,
  updateEmployeeZoneTestNow,
  getCompanySetting,
  addEmployeeQuestion,
  udpatePersonalization,
  getRegions,
  externalTestState,
  zoneLevelExternalTest,
  fetchAllCrewMembers,
  addDepartment,
  updateEmployeeTest,
  getEmployeeTestFromMD,
  getPrevEnvData,
  updateEmployeeCallTime,
  updateDepartmentCallTime,
  addClient,
  addlogs,
  updateMainContact,
  updateTestingCord,
  updateEmpAcknowledgeBy,
  manualClearedEmployee,
  deleteDailyLog,
  saveEmployeeLogs,
  updateEmpProgram,
  getRegisterData,
  updateEmployeeDefault,
  updateEmpProgramName,
  updateEmployeeAddress,
  updateEmployeeSub,
  getCompnayList,
  testFunc,
  employeeAuditTrail,
  createHrEmployee,
  assignedDepartmentToEmp,
  getCompanyTestDoneList,
  updateZonesTests,
  quesSchedulesUpdate,
  updateExceptedOnSet,
  updateDriveAccessMembers,
  updateCallTimeLogs,
  parseMessageCallTime,
  createCalendarSchedule,
  deleteCalendarSchedule,
  updateCalendarSchedule,
  getCalendarSchedules,
  getBlockOutDays,
  updateEmpEmails,
  removeExternalTest,
  addLogsRemoveExternalTest,
  updateExternalTestResult,
  uploadExternalTest,
  getTestListFromPG,
  saveFileExternalStorage,
  createSystemUser,
  updateCustomSchedule,
  removeCustomSch,
  updateConnectedTo,
  sendEmailToPendingRelease,
  mergeEmployeeLogs,
  customReSchedule,
  getProxyContactData,
  updateProxyContactData,
  addProxyContactData,
  deleteProxyContactData,
  fetchAllCustomCallTime,
  updateCustomCallTime,
  deleteCustomCallTimeDates,
  getAllUsersForShow,
};
